import React, { useEffect, useState } from "react";
import DashboardCasino from "./DashoardMenu";
import axios from "axios";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoBets = () => {
  const [bets, setBets] = useState([]);
  const { colors } = useWebSocket();


  useEffect(() => {
    const getBets = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/bet/getAll`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      const slicedBets = response.data.slice(0, 2500);
      setBets(slicedBets);
    };
    getBets();
  }, []);

  return (
    <div className="flex flex-row"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full p-8">
        <h1 className="mb-5 text-4xl font-bold text-white">Bets</h1>
        <div className="overflow-y-auto max-h-[75vh] w-1/2 justify-center flex">
          <table className="w-full text-black bg-white">
            <thead>
              <tr>
                <th className="py-2">User</th>
                <th className="py-2">Amount</th>
                <th className="py-2">Result</th>
                <th className="py-2">Profit/Loss</th>
              </tr>
            </thead>
            <tbody>
              {bets.map((bet) => (
                <tr key={bet._id}>
                  <td className="py-2">{bet.username}</td>
                  <td className="py-2">$ {bet.amount}</td>
                  <td className="py-2">{bet.result}</td>
                  <td className="py-2">
                    {bet.profitOrLoss !== null && bet.profitOrLoss !== undefined
                      ? "$ " + bet.profitOrLoss
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CasinoBets;
