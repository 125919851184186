import axios from "axios";
import React, { useEffect, useState } from "react";
import { notify } from "../Toast/Toast";
import DashboardCasino from "./DashoardMenu";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoBotsCreate = () => {
  const [botName, setBotName] = useState("");
  const navigate = useNavigate();
  const { colors } = useWebSocket();

  const handleName = (e) => {
    setBotName(e.target.value);
  };

  const handleAddBot = () => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + `/bots/create`,
        {
          username: botName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const content = (
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Bot {botName} created successfully!
              </div>
            </div>
          </div>
        );
        notify(content, "success", colors);
        setBotName("");
      });
  };
  const generateRandomUsername = () => {
    // Arrays of adjectives and nouns for username generation
    const adjectives = [
      "Swift",
      "Silent",
      "Brave",
      "Mighty",
      "Clever",
      "Noble",
      "Sly",
      "Wise",
      "Fierce",
      "Bold",
      "Loyal",
      "Jolly",
      "Happy",
      "Bright",
      "Eager",
      "Shiny",
      "Lucky",
      "Curious",
      "Witty",
      "Charming",
      "Fearless",
      "Gentle",
      "Radiant",
      "Daring",
      "Gallant",
      "Mystic",
      "Stealthy",
      "Epic",
      "Heroic",
      "Stormy",
      "Majestic",
      "Blazing",
      "Vigilant",
      "Valiant",
      "Nimble",
      "Vivid",
      "Mirthful",
      "Vast",
      "Dazzling",
      "Electric",
      "Silent",
      "Crimson",
      "Frosty",
      "Thunderous",
      "Golden",
      "Vibrant",
      "Shadowy",
      "Whispering",
      "Eternal",
      "Divine",
      "Cosmic",
      "Phantom",
      "Celestial",
      "Raging",
      "Zealous",
      "Arcane",
      "Swift",
      "Glorious",
      "Grim",
      "Daring",
      "Harmonic",
      "Infernal",
      "Primal",
      "Blissful",
      "Glimmering",
      "Radiant",
      "Serene",
      "Spectral",
      "Vigilant",
      "Humble",
      "Benevolent",
      "Chivalrous",
      "Proud",
      "Tenacious",
      "Zephyr",
      "Gleaming",
      "Nebulous",
      "Infinite",
      "Cunning",
      "Vivacious",
    ];

    const nouns = [
      "Lion",
      "Tiger",
      "Panther",
      "Fox",
      "Wolf",
      "Eagle",
      "Hawk",
      "Dragon",
      "Bear",
      "Falcon",
      "Knight",
      "Wizard",
      "Ranger",
      "Ninja",
      "Samurai",
      "Pirate",
      "Viking",
      "Ghost",
      "Warrior",
      "Sage",
      "Phoenix",
      "Griffin",
      "Shadow",
      "Starlord",
      "Rogue",
      "Hunter",
      "Sorcerer",
      "Guardian",
      "Raven",
      "Owl",
      "Cheetah",
      "Leopard",
      "Lynx",
      "Cobra",
      "Serpent",
      "Golem",
      "Druid",
      "Paladin",
      "Samurai",
      "Ronin",
      "Shaman",
      "Berserker",
      "Assassin",
      "Mystic",
      "Oracle",
      "Gladiator",
      "Samurai",
      "Monk",
      "Samurai",
      "Jester",
      "Titan",
      "Reaper",
      "Wanderer",
      "Nomad",
      "Crusader",
      "Behemoth",
      "Champion",
      "Sphinx",
      "Chimera",
      "Wraith",
      "Gargoyle",
      "Specter",
      "Minotaur",
      "Harpy",
      "Unicorn",
      "Pegasus",
      "Hydra",
      "Cyclops",
      "Basilisk",
      "Gryphon",
      "Necromancer",
      "Invoker",
      "Warlord",
      "Protector",
      "Sphinx",
      "Pharaoh",
      "Demon",
      "Angel",
      "Djinn",
      "Demon",
      "Valkyrie",
      "Sentinel",
      "Ravager",
      "Abyss",
      "Stalker",
      "Nightmare",
      "Prophet",
      "Crusader",
      "Wanderer",
      "Outlaw",
    ];

    // Randomly select an adjective and a noun from the arrays
    const randomAdjective =
      adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    // Generate a random number for uniqueness
    const randomNumber = Math.floor(Math.random() * 1000);

    // Combine the adjective, noun, and number to create a username
    const username = `${randomAdjective}${randomNoun}${randomNumber}`;

    return username;
  };
  const randomizeBotName = () => {
    // axios.get('https://randomuser.me//api').then(response => {
    //     setBotName(response.data.results[0].login.username);
    //     console.log(response.data.results[0].login.username);
    // }
    // );
    const botname = generateRandomUsername();
    setBotName(botname);
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <div className="flex flex-row"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full p-8">
        <div>
          <button
            className={`p-2 rounded-lg mr-2 hover:brightness-110 transition-all duration-200 ease-in-out text-[#b1b6c6]`}
            onClick={() => navigate("/casino-dashboard/bots")}
            style={{
              backgroundColor: newShade(colors.secondaryColorBG, 10),
            }}
          >
            <span
              className={`font-semibold transition-all duration-200`}
              style={{
                color:
                  window.location.pathname === "/casino-dashboard/bots" &&
                  colors?.profitColor,
                textShadow:
                  window.location.pathname === "/casino-dashboard/bots" &&
                  `0px 0px 8px ${colors?.profitColor}`,
              }}
            >
              Bots
            </span>
          </button>
          <button
            className={`p-2 rounded-lg mr-2 hover:brightness-110 transition-all duration-200 ease-in-out text-[#b1b6c6]`}
            onClick={() => navigate("/casino-dashboard/bots/create")}
            style={{
              backgroundColor: newShade(colors.secondaryColorBG, 10),
            }}
          >
            <span
              className={`font-semibold transition-all duration-200
              `}
              style={{
                color:
                  window.location.pathname ===
                  "/casino-dashboard/bots/create" && colors?.profitColor,
                textShadow:
                  window.location.pathname ===
                  "/casino-dashboard/bots/create" &&
                  `0px 0px 8px ${colors?.profitColor}`,
              }}
            >
              Create Bots
            </span>
          </button>
        </div>
        <div
          className="p-4 mt-5 rounded-md"
          style={{ backgroundColor: newShade(colors?.secondaryColorBG, 20) }}
        >
          <h1 className="mb-4 text-4xl font-bold text-white">Create Bot</h1>
          <div className="flex flex-row gap-x-2">
            <input
              type="text"
              className="block w-full p-2 text-sm font-bold text-white rounded-md ps-4 focus-visible:outline-none"
              placeholder="username..."
              value={botName ? botName : ""}
              onChange={handleName}
              style={{
                backgroundColor: colors?.mainColorBG,
              }}
            ></input>
            <button
              className="p-2 text-sm font-medium text-black uppercase transition-all duration-200 rounded hover:brightness-110"
              style={{
                backgroundColor: colors?.profitColor,
              }}
              onClick={randomizeBotName}
            >
              Randomize
            </button>
          </div>
          <div className="flex flex-col pt-2 gap-y-2">
            <button
              className="p-2 rounded text-black text-sm uppercase font-medium transition-all duration-200 bg-[#72F238] hover:brightness-110"
              onClick={handleAddBot}
              disabled={!botName}
              style={{
                backgroundColor: colors?.profitColor,
              }}
            >
              Add Bot
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoBotsCreate;
