import { useEffect, useState } from "react";
import DashboardCasino from "./DashoardMenu";
import axios from "axios";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoStatistics = () => {
  const { colors } = useWebSocket();
  const [users, setUsers] = useState([]);
  const [bets, setBets] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/user/getAll`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setUsers(response.data);
    };

    const getBets = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/bet/getAll`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setBets(response.data);
    };

    getUsers();
    getBets();
  }, []);
  const totalUsers = users?.length;
  const totalBets = bets?.length;

  const calculateTotalRevenue = () => {
    return bets
      .filter((bet) => bet.result !== "pending")
      .reduce((total, bet) => total - bet.profitOrLoss, 0)
      .toFixed(2);
  };

  const calculateRevenueOverTime = (period) => {
    const now = new Date();
    const filteredBets = bets.filter((bet) => {
      const betDate = new Date(bet.updatedAt);
      if (bet.result === "pending") {
        return false;
      }
      switch (period) {
        case "daily":
          return betDate.toDateString() === now.toDateString();
        case "weekly":
          const weekAgo = new Date();
          weekAgo.setDate(now.getDate() - 7);
          return betDate >= weekAgo;
        case "monthly":
          return (
            betDate.getMonth() === now.getMonth() &&
            betDate.getFullYear() === now.getFullYear()
          );
        default:
          return false;
      }
    });
    return filteredBets
      .reduce((total, bet) => total - bet.profitOrLoss, 0)
      .toFixed(2);
  };

  const calculateCashFlow = () => {
    return bets
      .filter((bet) => bet.result !== "pending")
      .reduce((total, bet) => total - bet.profitOrLoss, 0)
      .toFixed(2);
  };

  const mostPopularCoin = () => {
    const coinCount = {};
    bets.forEach((bet) => {
      const coin = bet.coin.replace(/usdt$/i, "");
      coinCount[coin] = (coinCount[coin] || 0) + 1;
    });
    return Object.keys(coinCount).reduce(
      (a, b) => (coinCount[a] > coinCount[b] ? a : b),
      ""
    );
  };

  const leastPopularCoin = () => {
    const coinCount = {};
    bets.forEach((bet) => {
      const coin = bet.coin.replace(/usdt$/i, "");
      coinCount[coin] = (coinCount[coin] || 0) + 1;
    });
    return Object.keys(coinCount).reduce(
      (a, b) => (coinCount[a] < coinCount[b] ? a : b),
      ""
    );
  };

  const mostFrequentBetSize = () => {
    const betSizeCount = {};
    bets.forEach((bet) => {
      betSizeCount[bet.amount] = (betSizeCount[bet.amount] || 0) + 1;
    });
    return Object.keys(betSizeCount).reduce(
      (a, b) => (betSizeCount[a] > betSizeCount[b] ? a : b),
      0
    );
  };

  const AverageBetSize = () => {
    const totalAmount = bets.reduce((total, bet) => total + bet.amount, 0);
    return (totalAmount / (totalBets || 1)).toFixed(2); // Avoid division by zero
  };

  const winLossRatio = () => {
    let wins = 0;
    let losses = 0;
    bets.forEach((bet) => {
      if (bet.result === "win") wins++;
      else if (bet.result !== "win") losses++;
    });
    return (wins / (losses || 1)).toFixed(2); // Avoid division by zero
  };

  const activeBetsCount = () => {
    return bets.filter((bet) => bet.result === "pending").length;
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <div className="flex flex-row"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full p-8">
        <h1 className="mb-5 text-4xl font-bold text-white"> Statistics</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-h-[75vh] w-full">
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Total Revenue</p>
            <p className="text-3xl font-bold text-white">
              $ {calculateTotalRevenue()}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Daily Revenue</p>
            <p className="text-3xl font-bold text-white">
              $ {calculateRevenueOverTime("daily")}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Weekly Revenue</p>
            <p className="text-3xl font-bold text-white">
              $ {calculateRevenueOverTime("weekly")}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Monthly Revenue</p>
            <p className="text-3xl font-bold text-white">
              $ {calculateRevenueOverTime("monthly")}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Cash Flow</p>
            <p className="text-3xl font-bold text-white">
              $ {calculateCashFlow()}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Total Users</p>
            <p className="text-3xl font-bold text-white">{totalUsers}</p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">
              Most Popular Coin
            </p>
            <p className="text-3xl font-bold text-white">{mostPopularCoin()}</p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">
              Least Popular Coin
            </p>
            <p className="text-3xl font-bold text-white">
              {leastPopularCoin()}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">
              Most Frequent Bet Size
            </p>
            <p className="text-3xl font-bold text-white">
              $ {mostFrequentBetSize()}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">
              Average Bet Sizes
            </p>
            <p className="text-3xl font-bold text-white">
              $ {AverageBetSize()}
            </p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Win/Loss Ratio</p>
            <p className="text-3xl font-bold text-white">{winLossRatio()}</p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">
              Total Bets Placed
            </p>
            <p className="text-3xl font-bold text-white">{totalBets}</p>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
            }}
          >
            <p className="text-xl font-semibold text-white">Active Bets</p>
            <p className="text-3xl font-bold text-white">{activeBetsCount()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoStatistics;
