import React, { useEffect, useState } from "react";
import DashboardCasino from "./DashoardMenu";
import axios from "axios";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoUsers = () => {
  const [users, setUsers] = useState([]);
  const { colors } = useWebSocket();

  const calculateTotalAmount = (bets) => {
    return bets?.reduce((total, bet) => total + bet.amount, 0) || 0;
  };

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/user/getAll`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setUsers(response.data);
    };

    getUsers();
  }, []);

  return (
    <div className="flex flex-row"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full p-8">
        <h1 className="mb-5 text-4xl font-bold text-white">Users</h1>
        <div className="overflow-y-auto max-h-[75vh] w-1/2 justify-center flex">
          <table className="w-full text-black bg-white">
            <thead>
              <tr>
                <th className="py-2">Name</th>
                <th className="py-2">Total Bets</th>
                <th className="py-2">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user?.id}>
                  <td className="py-2">{user.username}</td>
                  <td className="py-2">{user.bets?.length}</td>
                  <td className="py-2">
                    $ {calculateTotalAmount(user.bets).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CasinoUsers;
