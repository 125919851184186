import React, { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../context/WebSocketProvider";

const DashboardCasino = () => {
  const { colors } = useWebSocket();
  const navigate = useNavigate();
  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };
  return (
    <div className="containerNavbar flex flex-row">
      <div className="w-[285px]  text-white overflow-y-hidden flex-shrink-0" style={{
        backgroundColor: colors?.secondaryColorBG,
      }}>
        <h2 className="my-4 text-2xl font-semibold text-center">Dashboard</h2>
        <ul>
          <li
            className={`p-4 cursor-pointer`}
            onClick={() => navigate("/casino-dashboard/statistics")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/statistics" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Statistics
          </li>
          <li
            className={`p-4 cursor-pointer`}
            onClick={() => navigate("/casino-dashboard/crypto-coins")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/crypto-coins" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Crypto Coins
          </li>
          <li
            className={`p-4 cursor-pointer `}
            onClick={() => navigate("/casino-dashboard/users")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/users" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Users
          </li>{" "}
          <li
            className={`p-4 cursor-pointer `}
            onClick={() => navigate("/casino-dashboard/bots")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/bots" || window.location.pathname === "/casino-dashboard/bots/create" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Bots
          </li>
          <li
            className={`p-4 cursor-pointer `}
            onClick={() => navigate("/casino-dashboard/bets")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/bets" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Bets
          </li>
          <li
            className={`p-4 cursor-pointer `}
            onClick={() => navigate("/casino-dashboard/colors")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/colors" || window.location.pathname === "/casino-dashboard/colors/edit" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Colors
          </li>
          <li
            className={`p-4 cursor-pointer `}
            onClick={() => navigate("/casino-dashboard/settings")}
            style={{
              backgroundColor: window.location.pathname === "/casino-dashboard/settings" ? newShade(colors?.secondaryColorBG, 20) : "",
            }}
          >
            Settings
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardCasino;
