import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { AuthContext } from "../../context/auth.context";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useWebSocket } from "../../context/WebSocketProvider";
import axios from 'axios';
import cryptoConfig from '../../cryptoConfig.js';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';
import { useBalanceUser } from '../../context/balanceuser.jsx';
import { useBetContext } from "../../context/bet.context.jsx";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { motion, AnimatePresence } from 'framer-motion';
import Portal from '../Portal/Portal.jsx'
import AnimatedNumber from '../AnimatedNumber/AnimatedNumber.jsx';
import { MdOutlineAttachMoney } from "react-icons/md";

const RightSidebar = ({ coin }) => {
  const { isLoggedIn, user } = useContext(AuthContext);
  const { publicBets, sendMessage, closedBets, activeBets, cryptoData, colors } = useWebSocket();
  const [bet, setBet] = useState('up');
  const [amount, setAmount] = useState(10.00);
  const [result, setResult] = useState(null);
  const [betLocked, setBetLocked] = useState(false);
  const [multiplier, setMultiplier] = useState(10);
  const [activeTab, setActiveTab] = useState('PUBLIC');
  const [openTooltip, setOpenTooltip] = useState(null);
  const tooltipRef = useRef(null);
  const cryptoId = cryptoConfig[coin].id;
  const steps = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000];
  const currentPrice = cryptoData.data[coin] ? cryptoData.data[coin] : null;
  const { BalanceUser } = useBalanceUser();
  const { selectedBet, setSelectedBet } = useBetContext();
  const containerRef = useRef(null);

  const getAnimationProps = () => {
    if (activeTab === 'ACTIVE') {
      return {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: [0, 0.5, 1], x: [100, -10, 0] }, // Synchronized keyframes
        exit: { opacity: [1, 0.5, 0], x: [0, -10, 100] },
        transition: {
          duration: 0.4, // Increased duration for smoother bump
          ease: "easeOut", // Easing function
          times: [0, 0.5, 1], // Timing for each keyframe
        },
      };
    } else {
      return {
        initial: { opacity: 1, y: -70 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 70 },
        transition: { duration: 0.5 },
      };
    }
  };

  const handleSelectBet = (bet) => {
    // Deselect if the bet is already selected
    if (selectedBet && selectedBet._id === bet._id) {
      setSelectedBet(null);
    } else {
      setSelectedBet(bet);
    }
  };

  const formatDate = (date) => {
    return format(new Date(date), 'MMM dd, HH:mm:ss');  // Customize the date format as needed
  };

  // Format numeric values for display
  const getFormattedValue = (value, symbol) => {
    if (value !== null) {
      const precision = cryptoConfig[symbol]?.precision || 2;
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }).format(value);
    }
    return "-";
  };
  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== 'string') {
      console.error('Invalid hexColor provided:', hexColor);
      return '#000000'; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpenTooltip(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  useEffect(() => {
    const handleScroll = () => {
      setOpenTooltip(null); // Close the tooltip on scroll
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const calculateTriggerPrice = (entryPrice, multiplier, bet) => {
    const MIN_CHANGE = 0.00040; // Minimum change to prevent bust price being zero

    // Ensure the multiplier is greater than 1
    if (multiplier <= 1) {
      multiplier = 1 + MIN_CHANGE;
    }

    // Determine the price change based on the bet direction
    const P_close = bet === 'up' ? entryPrice * (1 - 1 / multiplier) : entryPrice * (1 + 1 / multiplier);

    // Return the calculated trigger price with the appropriate precision
    return P_close.toFixed(cryptoConfig[coin]?.precision);
  };

  useLayoutEffect(() => {
    if (isLoggedIn && activeBets.length > 0) {
      setActiveTab('ACTIVE');
    } else if (isLoggedIn && activeBets.length === 0 && closedBets.length > 0) {
      setActiveTab('CLOSED');
    }
  }, [isLoggedIn, activeBets]);

  // Main effect to handle isLoggedIn and coin changes
  useEffect(() => {
    setResult(null);

    if (!isLoggedIn) {
      setActiveTab('PUBLIC');
    }
  }, [isLoggedIn, coin]);

  const handleBet = async () => {
    if (!isLoggedIn) {
      alert("You must be logged in to place a bet.");
      return;
    }

    const currentPriceBet = currentPrice;

    setResult(null);
    setBetLocked(true);

    // Calculate bust price
    const bustPriceBet = calculateTriggerPrice(currentPriceBet, multiplier, bet);

    // Construct the bet data
    const betData = {
      userId: user?._id,
      username: user?.username,
      direction: bet,
      amount: parseFloat(amount),
      multiplier: parseFloat(multiplier),
      entryPrice: currentPriceBet,
      bustPrice: bustPriceBet,
      avatar: user?.avatar,
      cryptoId: cryptoId,
      coin: coin,
      balance: BalanceUser
    };

    try {
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + `/bet/addBet`, { betData }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.status !== 201) {
        throw new Error('Failed to save bet');
      }

      const savedBet = response.data;
      setBetLocked(false);
      setActiveTab('ACTIVE');
      handleSelectBet(savedBet);

      // Send the bet data to the WebSocket server
      const wsMessage = {
        type: 'newBet',
        ...betData,
        _id: savedBet._id,
        result: savedBet.result,
        createdAt: savedBet.createdAt
      };
      sendMessage(wsMessage);
    } catch (error) {
      console.error('Error saving bet:', error);
      alert('There was an error placing your bet. Please try again.');
      setBetLocked(false);
    }
  };

  const handleCashOut = async (betId, exitPrice, userId) => {
    try {
      // Send the cashout request to the backend
      const response = await axios.patch(process.env.REACT_APP_SERVER_URL + `/bet/cashout`, {
        betId,
        exitPrice,
        userId
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to cash out');
      }

      const updatedBet = response.data;
      setResult(updatedBet.result);
      if (selectedBet?._id === updatedBet?._id) setSelectedBet(null);
      setBetLocked(false);

      // Send the updated bet result to the WebSocket server
      const wsMessage = {
        type: 'cashedOutBet',
        username: updatedBet.username,
        userId: updatedBet.userId,
        direction: updatedBet.direction,
        amount: updatedBet.amount,
        multiplier: updatedBet.multiplier,
        result: updatedBet.result,
        entryPrice: updatedBet.entryPrice,
        exitPrice: updatedBet.exitPrice,
        avatar: updatedBet.avatar,
        cryptoId: updatedBet.cryptoId,
        bustPrice: updatedBet.bustPrice,
        coin: updatedBet.coin,
        _id: updatedBet._id,
        createdAt: updatedBet.createdAt,
        updatedAt: updatedBet.updatedAt,
        profitOrLoss: updatedBet.profitOrLoss,
        roi: updatedBet.roi
      };

      sendMessage(wsMessage);
    } catch (error) {
      console.error('Error cashing out:', error);
      alert('There was an error cashing out. Please try again.');
    }
  };

  const handleMultiplierChange = (e) => {
    const value = parseInt(e.target.value);
    setMultiplier(Math.min(1000, Math.max(1, value)));
  };

  const handleSliderChange = (e) => {
    const index = parseInt(e.target.value);
    setMultiplier(steps[index]);
  };

  const getSliderValue = () => {
    return steps.indexOf(multiplier);
  };

  const isValidMultiplier = multiplier >= 1 && multiplier <= 1000;

  const roundToTwo = (num) => {
    return Math.round(num * 100) / 100;
  };

  const getCurrentPriceForCoin = (coin) => {
    if (!cryptoData || typeof cryptoData !== 'object') {
      console.error('cryptoData is not an object:', cryptoData);
      return null;
    }
    return cryptoData.data[coin] ? parseFloat(cryptoData.data[coin]) : null;
  };

  const calculateProfitOrLossPercentage = (bet) => {
    const currentPrice = getCurrentPriceForCoin(bet.coin);
    if (!currentPrice) return { profitOrLoss: 0, roi: 0 };

    let percentageChange;

    if (bet.direction === 'up') {
      // For 'up' bet, profit if currentPrice > entryPrice, otherwise loss
      percentageChange = ((currentPrice - bet.entryPrice) / bet.entryPrice) * 100;
    } else if (bet.direction === 'down') {
      // For 'down' bet, profit if currentPrice < entryPrice, otherwise loss
      percentageChange = ((bet.entryPrice - currentPrice) / bet.entryPrice) * 100;
    }

    // Apply the multiplier
    percentageChange = roundToTwo(percentageChange * bet.multiplier);

    // Calculate profit or loss in monetary terms
    let profitOrLoss = roundToTwo((percentageChange / 100) * bet.amount);

    // Cap the loss to the amount betted
    if (profitOrLoss < -bet.amount) {
      profitOrLoss = roundToTwo(-bet.amount);
    }

    return {
      profitOrLoss: profitOrLoss.toFixed(2),
      roi: percentageChange.toFixed(2)
    };
  };

  const checkIfWin = (bet, profitOrLoss) => {
    return profitOrLoss > 0;
  };

  const renderBetList = (bets) => {
    const lastBets = bets.slice(0, 15);

    return (
      <div className="relative flex-1">
        {lastBets.length === 0 ? (
          <p className="text-sm text-gray-400">
            No bets available.
          </p>
        ) : (
          <div className="flex flex-col">
            <AnimatePresence initial={activeTab === 'ACTIVE'}>
              {lastBets.map((bet, index) => {
                const { profitOrLoss, roi } = bet.result === "pending"
                  ? calculateProfitOrLossPercentage(bet)
                  : {
                    profitOrLoss: Number(bet.profitOrLoss).toFixed(2),
                    roi: Number(bet.roi).toFixed(2),
                  };
                const isWin = bet.result === "pending" ? checkIfWin(bet, profitOrLoss) : bet.result === 'win';
                const isActiveBet = activeTab === 'ACTIVE';
                const handleClick = isActiveBet ? () => handleSelectBet(bet) : null;
                const isSelected = selectedBet && selectedBet._id === bet._id && activeTab === 'ACTIVE';

                const animationProps = getAnimationProps();

                return (
                  <motion.div
                    key={bet._id}
                    layout // Enable layout animations
                    initial={animationProps.initial}
                    animate={animationProps.animate}
                    exit={animationProps.exit}
                    transition={animationProps.transition}
                    className={`flex items-center justify-between px-7 py-4
                        ${isActiveBet ? 'cursor-pointer' : ''}`}
                    onMouseOver={(e) => {
                      if (isActiveBet && !isSelected) {
                        e.currentTarget.style.backgroundColor = colors?.mainColorBG;
                      }
                    }}
                    onMouseOut={(e) => {
                      if (isActiveBet && !isSelected) {
                        e.currentTarget.style.backgroundColor = colors?.secondaryColorBG;
                      }
                    }}
                    onClick={handleClick}
                    style={{
                      backgroundColor: isSelected ? colors?.mainColorBG : '',
                    }}
                  >
                    <div className="flex items-center text-left">
                      <div className='flex flex-row items-start'>
                        {activeTab === 'PUBLIC' && <img src={`images/avatar/${bet.avatar}`} loading="lazy" alt="avatar" className="w-8 mr-2" />}
                        <div className={`flex flex-col ${activeTab === 'PUBLIC' ? 'w-20' : 'w-16'}`}>
                          {activeTab === 'PUBLIC' ? <span className="text-[13px]">{bet.username.slice(0, 10)}</span> : <span className="text-[13px]">Bet</span>}
                          <p className="text-[13px] text-white text-nowrap flex flex-row items-center gap-x-1">$ {bet.amount?.toFixed(2)}</p>
                        </div>
                      </div>
                      <div className={`flex flex-col text-left pl-5`}>
                        <span className="text-[13px]">P&L:</span>
                        <p className={`text-[13px] text-nowrap`} style={{
                          color: profitOrLoss > 0 ? colors?.profitColor : profitOrLoss < 0 ? colors?.lossColor : colors?.secondaryColorBG ? '#b1b6c6' : 'white',
                        }}>
                          {profitOrLoss < 0 ? `-$ ` : `$ `}
                          <AnimatedNumber
                            value={Math.abs(Number(profitOrLoss))}
                            format={(val) => val.toFixed(2)}
                          />
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center self-end text-left">
                      <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`} loading="lazy" alt="coin" className="w-5 h-5 rounded-full mr-0.5" />
                      <p className={`text-sm pr-0.5`}>{bet.direction === 'up' ? <FaAngleDoubleUp style={{ color: colors?.profitColor }} /> : <FaAngleDoubleDown style={{ color: colors?.lossColor }} />}</p>
                      {activeTab === 'CLOSED' && (
                        bet.result !== 'busted' ? (
                          <div className="flex flex-row items-center pr-2 text-left">
                            <p className='px-2.5 py-1 text-[11px] leading-[15px] text-gray-400 rounded-md' style={{
                              backgroundColor: newShade(colors?.mainColorBG, 20),
                            }}>closed</p>
                          </div>
                        ) : (
                          <div className="flex flex-row items-center pr-2 text-left">
                            <p className='px-2.5 py-1 text-[11px] leading-[15px] rounded-md' style={{
                              color: colors?.lossColor,
                              backgroundColor: colors?.lossColor + '21',
                            }}> busted</p>
                          </div>
                        )
                      )}
                      {activeTab === 'ACTIVE' && (
                        <div className="flex flex-row items-center self-end text-left">
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the bet from being selected
                              handleCashOut(bet._id, getCurrentPriceForCoin(bet.coin), user?._id);
                            }}
                            className={`px-2.5 py-1 text-[11px] mr-2 leading-[15px] rounded-md`}
                            style={{
                              backgroundColor: isWin ? colors?.profitColor + '21' : colors?.lossColor + '21',
                              color: isWin ? colors?.profitColor : colors?.lossColor,
                            }}
                          >
                            CLOSE
                          </button>
                        </div>
                      )}
                      <div className="flex flex-row items-center text-left" ref={openTooltip === bet._id ? tooltipRef : null}>
                        <MdOutlineKeyboardArrowDown
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenTooltip(openTooltip === bet._id ? null : bet._id);
                          }}
                          data-tooltip-id={`betInfo-${bet._id}`}
                          data-tooltip-place="top"
                          className="text-xl text-gray-400 cursor-pointer select-none hover:text-gray-600"
                        />
                        <Portal>
                          <Tooltip
                            id={`betInfo-${bet._id}`}
                            openOnClick={true}
                            clickable={false}
                            isOpen={openTooltip === bet._id}
                            opacity={1}
                            className="tooltip-custom"
                          >
                            <div className="p-7 text-[13px] text-left shadow-2xl flex flex-col space-y-4"
                              style={{
                                backgroundColor: colors?.secondaryColorBG,
                              }}>
                              <div className='flex flex-row items-center'>
                                <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`} loading="lazy" alt="coin" className="w-5 h-5 mr-1 rounded-full" />
                                <p className={`text-base mr-2`}>{bet.direction === 'up' ? <FaAngleDoubleUp style={{ color: colors?.profitColor }} /> : <FaAngleDoubleDown style={{ color: colors?.lossColor }} />}</p>
                                {activeTab === 'ACTIVE' ? <p className='text-[13px] font-bold text-gray-400 uppercase'>{formatDate(bet.createdAt)}</p> : <p className='text-[13px] font-bold text-gray-400 uppercase'>{formatDate(bet.updatedAt)}</p>}
                              </div>
                              {activeTab === 'PUBLIC' && <div className="flex items-center text-left">
                                <img src={`images/avatar/${bet.avatar}`} loading="lazy" alt="avatar" className="w-8 h-8 mr-2 rounded-full" />
                                <div className='flex flex-col items-start'>
                                  <span className="text-sm font-bold">{bet.username}</span>
                                </div>
                              </div>}
                              <div className='flex flex-row space-x-4'>
                                <div className='flex flex-col space-y-4'>
                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Entry Price</p>
                                    <p className='text-sm font-medium'>{getFormattedValue(bet.entryPrice, bet.coin)}</p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Wager Amount</p>
                                    <p className='text-sm font-medium'>{bet.amount.toFixed(2)}</p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Multiplier</p>
                                    <p className='text-sm font-medium'>x{bet.multiplier.toFixed(2)}</p>
                                  </div>

                                  {bet.result !== 'pending' && <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Exit Price</p>
                                    <p className='text-sm font-medium'>{getFormattedValue(bet.exitPrice, bet.coin)}</p>
                                  </div>}
                                </div>

                                <div className='flex flex-col space-y-4'>
                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Bust Price</p>
                                    <p className='text-sm font-medium'>{getFormattedValue(bet.bustPrice, bet.coin)}</p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Profit & Loss</p>
                                    <p className={`text-sm font-medium`}
                                      style={{
                                        color: profitOrLoss > 0 ? colors?.profitColor : profitOrLoss < 0 ? colors?.lossColor : colors?.secondaryColorBG ? '#b1b6c6' : 'white',
                                      }}>
                                      {profitOrLoss < 0 ? `-$ ` : `$ `}
                                      <AnimatedNumber
                                        value={Math.abs(Number(profitOrLoss))}
                                        format={(val) => val.toFixed(2)}
                                      />
                                    </p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>ROI</p>
                                    <p className={`text-sm font-medium`} style={{
                                      color: roi > 0 ? colors?.profitColor : roi < 0 ? colors?.lossColor : colors?.secondaryColorBG ? '#b1b6c6' : 'white',
                                    }}>
                                      {roi < 0 ? `-` : ``}
                                      <AnimatedNumber
                                        value={Math.abs(Number(roi))}
                                        format={(val) => val.toFixed(2)}
                                      />
                                      %</p>
                                  </div>

                                  {bet.result !== 'pending' && <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Status</p>
                                    <p className={`capitalize font-medium text-sm`} style={{ color: bet.result === 'busted' && colors?.lossColor }}>{bet.result === 'busted' ? 'busted' : 'cashed out'}</p>
                                  </div>}
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </Portal>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </div>
        )}
        {(lastBets.length > 13 && activeTab === 'PUBLIC') && (
          <div
            className="absolute bottom-0 left-0 w-full pointer-events-none"
            style={{
              height: '16%',
              background: `linear-gradient(${colors?.secondaryColorBG}00 0%, ${colors?.secondaryColorBG}FF 80%)`,
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="hidden lg:flex flex-col h-full text-[#b1b6c6]"
      style={{
        backgroundColor: colors?.secondaryColorBG,
      }}>
      <div className='p-7'>
        <h1 className='mb-6 text-base font-bold text-left text-white'>Place Bet</h1>
        <div className='flex flex-row justify-between gap-x-3'>
          <div className='w-3/5'>
            <h2 className="mb-2 text-[13px] text-left">Price up or down?</h2>
            <div className={`flex flex-row mb-6 w-full p-0.5 rounded-md ${betLocked ? 'pointer-events-none opacity-50' : ''}`} style={{
              backgroundColor: colors?.mainColorBG,
            }}>
              <button
                onClick={() => setBet('up')}
                className={` flex flex-row justify-center items-center gap-x-1 p-2 rounded text-[13px] transition-all duration-200 w-full ${betLocked ? 'opacity-50 cursor-not-allowed' : ''}`}
                style={{
                  backgroundColor: bet === 'up' && colors?.profitColor + '42',
                  color: bet === 'up' ? colors?.profitColor : '#b1b6c6',
                }}
              >
                <FaAngleDoubleUp />
                Up
              </button>
              <button
                onClick={() => setBet('down')}
                className={`flex flex-row justify-center items-center gap-x-1 p-2 rounded text-[13px] transition-all duration-200 w-full
                ${betLocked ? 'opacity-50 cursor-not-allowed' : ''}`}
                style={{
                  backgroundColor: bet === 'down' && colors?.lossColor + '42',
                  color: bet === 'down' ? colors?.lossColor : '#b1b6c6',
                }}
              >
                <FaAngleDoubleDown />
                Down
              </button>
            </div>
          </div>
          <div className={`w-2/5 mb-6 text-left ${betLocked ? 'pointer-events-none opacity-50' : ''}`}>
            <label className="flex mb-2 text-[13px]">Bet Amount</label>
            <div className="relative text-[13px]">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full p-2.5 text-white rounded focus:outline-none pl-8"
                disabled={betLocked}
                style={{
                  backgroundColor: colors?.mainColorBG,
                }}
              />
              <span
                onClick={() => setAmount((prev) => (parseFloat(prev) / 2).toFixed(2))}
                className="absolute text-white transform -translate-y-1/2 left-4 top-1/2"
              >
                <MdOutlineAttachMoney />
              </span>
            </div>
          </div>
        </div>
        <div className={`w-full text-left ${betLocked ? 'pointer-events-none opacity-50' : ''}`}>
          <label className="flex mb-1 text-[13px]">Payout Multiplier</label>
          <div className='relative flex flex-row gap-x-3'>
            <input
              type="number"
              min="1"
              max="1000"
              value={multiplier}
              onChange={handleMultiplierChange}
              onBlur={handleMultiplierChange}
              className="w-3/5 p-2 text-white rounded focus:outline-none pl-8 text-[13px] font-light"
              disabled={betLocked}
              style={{
                backgroundColor: colors?.mainColorBG,
              }}
            />
            <span className='absolute text-sm font-semibold text-white transform -translate-y-1/2 left-3 top-1/2'><IoClose /></span>
            <div className="flex flex-col justify-center w-2/5 pl-4 text-[11px]">
              <span className="">Bust Price:</span>
              <span className="text-white">{calculateTriggerPrice(currentPrice, multiplier, bet)}</span>
            </div>
          </div>
          <input
            type="range"
            min="0"
            max={steps.length - 1}
            value={getSliderValue()}
            onChange={handleSliderChange}
            className="w-full mt-6 custom-slider"
            style={{
              background: `linear-gradient(90deg, ${colors?.firstColor} 0%, ${colors?.secondColor} 33%, ${colors?.thirdColor}  66%, ${colors?.fourthColor} 100%)`
            }}
            disabled={betLocked}
          />
          {!isValidMultiplier && (
            <p className="mt-2 text-sm"
              style={{
                color: colors?.lossColor,
              }}>Multiplier must be between 1 and 1000</p>
          )}
        </div>
        <div className="flex items-center justify-between mb-6 text-[11px]">
          <span className="text-white">x1 <span style={{
            color: colors?.profitColor,
          }}>Safe</span></span>
          <span style={{
            color: colors?.lossColor,
          }}>Wild <span className='text-white'>x1000</span></span>
        </div>
        <button
          onClick={handleBet}
          className={`w-full py-3 text-black text-sm uppercase font-medium transition-all duration-200 
            ${bet === 'up' ? ' hover:brightness-75' : bet === 'down' ? 'hover:brightness-75' : 'bg-gray-700'}
            rounded-[5px] disabled:opacity-50 disabled:cursor-not-allowed`}
          disabled={!amount || !bet || !isValidMultiplier || betLocked}
          style={{
            backgroundColor: bet === 'up' ? colors?.profitColor : colors?.lossColor,
          }}>
          Place Bet
        </button>
      </div>
      <div className="flex flex-col flex-1 pl-0 pr-0 overflow-hidden border-t border-black p-7">
        <div className="flex mb-8 ml-7 gap-x-5">
          {isLoggedIn && (
            <>
              <button
                onClick={() => setActiveTab('ACTIVE')}
                className={`text-[13px] transition-all duration-200`}
                style={{
                  color: activeTab === 'ACTIVE' ? colors?.profitColor : "#b1b6c6",
                  textShadow: activeTab === 'ACTIVE' ? `0px 0px 8px ${colors?.profitColor}` : 'none',
                }}
              >
                ACTIVE
              </button>
              <button
                onClick={() => setActiveTab('CLOSED')}
                className={`text-[13px] transition-all duration-200`}
                style={{
                  color: activeTab === 'CLOSED' ? colors?.profitColor : "#b1b6c6",
                  textShadow: activeTab === 'CLOSED' ? `0px 0px 8px ${colors?.profitColor}` : 'none',
                }}
              >
                CLOSED
              </button>
            </>)}
          <button
            onClick={() => setActiveTab('PUBLIC')}
            className={`text-[13px] transition-all duration-200`}
            style={{
              color: activeTab === 'PUBLIC' ? colors?.profitColor : "#b1b6c6",
              textShadow: activeTab === 'PUBLIC' ? `0px 0px 8px ${colors?.profitColor}` : 'none',
            }}
          >
            PUBLIC
          </button>
        </div>
        <div ref={containerRef} className="flex-1 overflow-x-hidden overflow-y-auto">
          {activeTab === 'PUBLIC' && renderBetList(publicBets)}
          {activeTab === 'ACTIVE' && renderBetList(activeBets)}
          {activeTab === 'CLOSED' && renderBetList(closedBets)}
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;