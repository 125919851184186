import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import RightSidebar from "../../components/RightSideBar/RightSidebar";
import "./HomePage.css";
import { useWebSocket } from "../../context/WebSocketProvider";
import LeftSideBar from "../../components/LeftSidebar/LeftSidebar";
import CandlebarsChart from "../../components/Chart/candlebarsChart";
import AreaChart from "../../components/Chart/areaChart";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import { useTimerangepick } from '../../context/timerangepick.jsx';
import { useBetContext } from "../../context/bet.context.jsx";

const HomePage = () => {
  const { cryptoData, error, cryptoInfo, setCurrentOHLC, colors } = useWebSocket();
  const [loading, setLoading] = useState(true);
  let { coin } = useParams();
  coin += 'USDT';
  const [key, setKey] = useState(0);
  const hiddenTimeRef = useRef(null);
  const { timerangepick } = useTimerangepick();
  const [cryptoList, setCryptoList] = useState([]);
  const firstTimeFrameChangeRef = useRef(true);
  const previousCoinRef = useRef(coin);
  const isActive = cryptoInfo.find(crypto => crypto.symbol === coin.replace(/USDT$/, ''))?.active;
  const hasLoadedRef = useRef(false);
  const { selectedBet, setSelectedBet } = useBetContext();

  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the initial value
    handleResize();

    // Recalculate on resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch initial crypto info
  useEffect(() => {
    const getCryptoInfo = async () => {
      if (Array.isArray(cryptoInfo)) {
        setCryptoList(cryptoInfo.map(crypto => ({
          ...crypto,
          price: null,
          previousPrice: null
        })));
      } else if (typeof cryptoInfo === 'object') {
        setCryptoList(Object.values(cryptoInfo).map(crypto => ({
          ...crypto,
          price: null,
          previousPrice: null
        })));
      } else {
        console.error("Unexpected data format:", cryptoInfo);
      }
    };

    getCryptoInfo();
  }, [cryptoInfo]);

  useEffect(() => {
    if (!hasLoadedRef.current && cryptoData.data && cryptoData.data[coin]) {
      setLoading(false);
      hasLoadedRef.current = true;
    }
  }, [cryptoData]);

  useLayoutEffect(() => {
    setCurrentOHLC([]); // Reset current OHLC on change

    // This block handles the change when timerangepick is not 'tick'
    if (timerangepick !== 'tick') {
      if (firstTimeFrameChangeRef.current) {
        firstTimeFrameChangeRef.current = false;
      } else {
        // Change the key to force re-render
        setKey(prevKey => prevKey + 1);
      }
    }

    // Check if the coin has changed and timerangepick is 'tick'
    if (timerangepick === 'tick' && previousCoinRef.current !== coin) {
      setKey(prevKey => prevKey + 1);
      // Update the previous coin reference
      previousCoinRef.current = coin;
    }

    if (selectedBet?.coin === coin) {
      const newSelectedBet = selectedBet ? selectedBet : null;
      setSelectedBet(null);
      setTimeout(() => setSelectedBet(newSelectedBet), 150);
    }

    // Always update the previous coin reference after handling
    previousCoinRef.current = coin;
  }, [coin, timerangepick]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        hiddenTimeRef.current = Date.now();
      } else if (document.visibilityState === 'visible') {
        if (hiddenTimeRef.current) {
          const hiddenDuration = Date.now() - hiddenTimeRef.current;
          if (hiddenDuration > 500) { // 2000 milliseconds = 2 seconds
            setKey(prevKey => prevKey + 1);
          }
          hiddenTimeRef.current = null;
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const updateOnlineStatus = () => {
      const onlineStatus = navigator.onLine;
      if (onlineStatus) {
        setLoading(false);
        setKey(prevKey => prevKey + 1);
      } else {
        setLoading(true);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  // Reload after 15 minutes
  useEffect(() => {
    const reloadAfter15Minutes = setTimeout(() => {
      window.location.reload();
    }, 15 * 60 * 1000); // 15 minutes in milliseconds

    return () => clearTimeout(reloadAfter15Minutes);
  }, []);

  if (isActive === false) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return;
  }

  if (error) {
    window.location.reload();
  }

  return (
    <div className="flex flex-col text-white containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <div className="flex flex-row h-full">
        <div className="xl:w-[285px] flex-shrink-0">
          <LeftSideBar coin={coin} />
        </div>
        <div className="flex flex-col justify-between flex-1 min-w-0 px-4 overflow-hidden lg:px-8 lg:justify-normal">
          <div className="pt-4 xl:pt-[27px] flex-1 flex flex-col pb-5 min-h-[200px] h-full select-none">
            {timerangepick === 'tick' ? <AreaChart key={key} coin={coin} cryptoList={cryptoList} /> : <CandlebarsChart key={key} coin={coin} cryptoList={cryptoList} />}
            {/*   <StockChart key={key} coin={coin} timeFrame={5000} /> */}
          </div>
          <div className="flex-shrink-0">
            <MobileMenu coin={coin} />
          </div>
        </div>
        <div className="lg:w-[350px] flex-shrink-0 h-full">
          <RightSidebar coin={coin} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
