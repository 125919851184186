import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useWebSocket } from '../../context/WebSocketProvider';
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { FaClock } from "react-icons/fa6";
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import cryptoConfig from '../../cryptoConfig.js';
import { useTimerangepick } from '../../context/timerangepick.jsx';

const Statistics = ({ coin, graphDataInitial }) => {
    const { statistics, cryptoData, colors } = useWebSocket();
    const { timerangepick } = useTimerangepick();
    const [graphData, setGraphData] = useState(graphDataInitial || []);
    const [data, setData] = useState({
        highestPrice: 0,
        lowestPrice: 0,
        trend: 'stable',
        percentageChange: 0,
        volatility: 0,
        maxPrice: 0,
        minPrice: 0,
        prices: [],
        bettingVolume: { upBetsPercentage: 0, downBetsPercentage: 0, biggestWin: { amount: 0, username: '', avatar: '', direction: '' } }
    });
    const [volatilityText, setVolatilityText] = useState('Very Low Volatility');
    const [volatilityBar, setVolatilityBar] = useState({
        width: '0%',
        background: '#1a1d29'
    });
    const [sparklineDimensions] = useState({ height: 15 });

    const precision = cryptoConfig[coin]?.precision || 2;

    const options = [
        { value: 'tick', label: 'Tick' },
        { value: 900000, label: '5s' },
        { value: 1800000, label: '15s' },
        { value: 3600000, label: '5m' },
        { value: 14400000, label: '15m' },
        { value: 43200000, label: '30m' },
        { value: 129600000, label: '1h' },
        { value: 388800000, label: '4h' },
        { value: 1166400000, label: '12h' }
    ];

    // Format numeric values for display
    const getFormattedValue = (value) => {
        if (value !== null) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision
            }).format(value);
        }
        return "-";
    };

    const getFilteredPrices = (prices, timerangepick) => {
        const currentTime = cryptoData.lastFetched;
        let timeLimit;

        switch (timerangepick) {
            case 900000:
                timeLimit = currentTime - 900000;
                break;
            case 1800000:
                timeLimit = currentTime - 1800000;
                break;
            case 3600000:
                timeLimit = currentTime - 3600000;
                break;
            case 14400000:
                timeLimit = currentTime - 14400000;
                break;
            case 43200000:
                timeLimit = currentTime - 43200000;
                break;
            case 129600000:
                timeLimit = currentTime - 129600000;
                break;
            case 388800000:
                timeLimit = currentTime - 388800000;
                break;
            case 1166400000:
                timeLimit = currentTime - 1166400000;
                break;
            default:
                timeLimit = currentTime - 900000; // Default to 5 seconds
        }

        return prices.filter((price, index) => {
            const priceTime = currentTime - (prices.length - index - 1) * 60 * 720;
            return priceTime >= timeLimit;
        });
    };

    const calculateMetrics = (prices, bettingVolume) => {
        const validBettingVolume = bettingVolume && typeof bettingVolume === 'object' && 'upBetsPercentage' in bettingVolume && 'downBetsPercentage' in bettingVolume && 'biggestWin' in bettingVolume
            ? bettingVolume
            : { upBetsPercentage: 0, downBetsPercentage: 0, biggestWin: { amount: 0, username: '', avatar: '', direction: '' } };

        const startPrice = prices[0];
        const endPrice = prices[prices.length - 1];
        const percentageChange = ((endPrice - startPrice) / startPrice) * 100;

        const returns = prices.slice(1).map((price, index) => (price - prices[index]) / prices[index]);
        const mean = returns.reduce((a, b) => a + b, 0) / returns.length;
        const squareDiffs = returns.map(value => Math.pow(value - mean, 2));
        const avgSquareDiff = squareDiffs.reduce((a, b) => a + b, 0) / squareDiffs.length;
        const volatility = Math.sqrt(avgSquareDiff) * Math.sqrt(60); // Annualized volatility
        const trend = percentageChange > 0 ? 'upwards' : percentageChange < 0 ? 'downwards' : 'stable';
        const maxPrice = Math.max(...prices);
        const minPrice = Math.min(...prices);

        return {
            highestPrice: maxPrice,
            lowestPrice: minPrice,
            percentageChange,
            volatility,
            trend,
            maxPrice,
            minPrice,
            prices,
            bettingVolume: validBettingVolume
        };
    };

    useLayoutEffect(() => {
        let intervalId;

        if (statistics[coin]) {
            intervalId = setInterval(() => {
                setGraphData((prevGraphData) => [...prevGraphData, cryptoData.data[coin]]);
                const combinedPrices = [...graphData, cryptoData.data[coin]];
                const filteredPrices = getFilteredPrices(combinedPrices, timerangepick);
                const metrics = calculateMetrics(filteredPrices, statistics[coin].bettingVolume);

                setData((prevState) => ({
                    ...prevState,
                    ...metrics
                }));

                // Adjust scaling factor based on observed range of volatility
                const maxVolatilityForBar = 0.02; // Adjusted max volatility for bar scaling
                const volatilityPercentage = Math.min((metrics.volatility / maxVolatilityForBar) * 5000, 100); // Scale to max 100%

                let background, text;
                if (volatilityPercentage < 10) {
                    background = `linear-gradient(to right, ${colors?.firstColor}, ${colors?.secondColor})`;
                    text = 'Very Low Volatility';
                } else if (volatilityPercentage < 40) {
                    background = `linear-gradient(to right, ${colors?.firstColor}, ${colors?.secondColor})`;
                    text = 'Low Volatility';
                } else if (volatilityPercentage < 60) {
                    background = `linear-gradient(to right, ${colors?.firstColor}, ${colors?.secondColor}, ${colors?.thirdColor})`;
                    text = 'Medium Volatility';
                } else if (volatilityPercentage < 90) {
                    background = `linear-gradient(to right, ${colors?.firstColor}, ${colors?.secondColor}, ${colors?.thirdColor}, ${colors?.fourthColor})`;
                    text = 'High Volatility';
                } else {
                    background = `linear-gradient(to right, ${colors?.firstColor}, ${colors?.secondColor}, ${colors?.thirdColor}, ${colors?.fourthColor})`;
                    text = 'Very High Volatility';
                }

                setVolatilityText(text);
                setVolatilityBar({
                    width: `${volatilityPercentage}%`,
                    background: background
                });
            }, 100);
        }

        // Clear the interval on component unmount or if dependencies change
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [cryptoData, statistics, coin, timerangepick, graphData, colors]);

    const getYCoordinate = (price, adjustment = 0) => {
        return sparklineDimensions.height - ((price - data.minPrice) / (data.maxPrice - data.minPrice)) * sparklineDimensions.height + adjustment;
    };

    const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    return (
        <div className="flex-1 hidden h-full overflow-x-hidden overflow-y-auto lg:flex md:overflow-x-auto md:max-h-max">
            <div className="flex flex-col w-full gap-1 text-white md:grid-flow-row lg:[@media(max-height:740px)]:max-h-[134px] md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-2">
                <div className="min-w-[180px] md:min-w-min p-4 md:p-6 text-left text-sm relative flex flex-col justify-between min-h-[115px] sm:min-h-[138px] md:min-h-[134px] md:max-h-[134px]" style={{ backgroundColor: colors?.secondaryColorBG }}>
                    <div className='flex flex-col justify-between h-full mb-2'>
                        <p className="mb-[15px] font-medium text-[13px]">Maximum Gain</p>
                        {data.bettingVolume.biggestWin.amount === 0 ? (
                            <p className="text-yellow-400 text-[12px]">No bets won in the last hour</p>
                        ) : (
                            <div className="flex items-center">
                                <img src={`images/avatar/${data.bettingVolume.biggestWin.avatar}`} alt={data.bettingVolume.biggestWin.username} className="w-8 mr-2" />
                                <div className="flex flex-col w-full text-left text-[13px]">
                                    <span className="text-gray-400">{data.bettingVolume.biggestWin.username.slice(0, 14)}</span>
                                    <div className='flex flex-row justify-between'>
                                        <span className='flex flex-row items-center' style={{ color: colors?.profitColor }}>$ {data.bettingVolume.biggestWin.amount.toFixed(2)}</span>
                                        <div className='flex items-center'>
                                            <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${data.bettingVolume.biggestWin.cryptoId}.png`} alt="coin" className="w-5 h-5 mr-1 rounded-full" />
                                            <span style={{
                                                color: data.bettingVolume.biggestWin.direction === 'up' ? colors?.profitColor : colors?.lossColor
                                            }}>
                                                {data.bettingVolume.biggestWin.direction === 'up' ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='absolute flex items-center text-gray-300 top-4 md:top-6 right-4 md:right-6 gap-x-1'>
                        <div className="flex items-center text-gray-300 gap-x-1 text-[13px]">
                            <FaClock className='w-[11px]' />
                            <p>{options.find(option => option.value === timerangepick)?.label}</p>
                        </div>
                    </div>
                </div>
                <div className="min-w-[180px] md:min-w-min p-4 md:p-6 text-left text-sm relative flex flex-col justify-between min-h-[115px] sm:min-h-[138px] md:min-h-[134px] md:max-h-[134px]" style={{ backgroundColor: colors?.secondaryColorBG }}>
                    <div className='flex flex-col justify-between h-full mb-2'>
                        <p className="mb-[15px] font-medium text-[13px]">Betting Volume</p>
                        {data.bettingVolume.upBetsPercentage === 0 && data.bettingVolume.downBetsPercentage === 0 ? (
                            <p className="text-yellow-400 text-[12px]">No bets made in the last hour</p>
                        ) : (
                            <div className="relative flex items-center mt-2 text-[13px]">
                                <p className="absolute left-0 transform -translate-y-1/2" style={{ color: colors?.profitColor }}>{data.bettingVolume.upBetsPercentage.toFixed(0)}%</p>
                                <p className="absolute right-0 transform -translate-y-1/2" style={{ color: colors?.lossColor }}>{data.bettingVolume.downBetsPercentage.toFixed(0)}%</p>
                                <div className="relative flex-1 h-1 mt-4 bg-gray-700 rounded">
                                    <div className="h-full rounded-l" style={{ width: `${data.bettingVolume.upBetsPercentage}%`, background: `repeating-linear-gradient(-45deg, ${colors?.profitColor}, ${newShade(colors.profitColor, 50)} 3px, ${newShade(colors.profitColor, 100)} 6px)` }}></div>
                                    <div className="absolute top-0 left-0 h-full rounded-r barwinprogress" style={{ width: `${data.bettingVolume.downBetsPercentage}%`, left: `${data.bettingVolume.upBetsPercentage}%`, background: `repeating-linear-gradient(-45deg, ${colors?.lossColor}, ${newShade(colors.lossColor, 20)} 3px, ${newShade(colors.lossColor, 50)} 6px)` }}></div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='absolute flex items-center text-gray-300 top-4 md:top-6 right-4 md:right-6 gap-x-1'>
                        <div className="flex items-center text-gray-300 gap-x-1 text-[13px]">
                            <FaClock className='w-[11px]' />
                            <p>{options.find(option => option.value === timerangepick)?.label}</p>
                        </div>
                    </div>
                </div>
                <div className="min-w-[180px] md:min-w-min p-4 md:p-6 text-left text-sm relative flex flex-col justify-between min-h-[115px] sm:min-h-[138px] md:min-h-[134px] md:max-h-[134px]" style={{ backgroundColor: colors?.secondaryColorBG }}>
                    <div className='flex flex-col justify-between h-full mb-2'>
                        <p className="mb-[15px] font-medium text-[13px]">Volatility Index</p>
                        <div>
                            <p className="mt-2 text-gray-400 text-[13px]">{volatilityText}</p>
                            <div className="relative w-full h-1 mt-2 overflow-hidden rounded" style={{ backgroundColor: colors?.mainColorBG }}>
                                <div className="absolute top-0 left-0 h-full" style={{ width: volatilityBar.width, background: volatilityBar.background }}></div>
                            </div>
                        </div>
                    </div>
                    <div className='absolute flex items-center text-gray-300 top-4 md:top-6 right-4 md:right-6 gap-x-1'>
                        <div className="flex items-center text-gray-300 gap-x-1 text-[13px]">
                            <FaClock className='w-[11px]' />
                            <p>{options.find(option => option.value === timerangepick)?.label}</p>
                        </div>
                    </div>
                </div>
                <div className="min-w-[180px] md:min-w-min p-4 md:p-6 text-left text-sm relative flex flex-col justify-between min-h-[115px] sm:min-h-[138px] md:min-h-[134px] md:max-h-[134px]" style={{ backgroundColor: colors?.secondaryColorBG }}>
                    <div>
                        <p className="mb-[15px] font-medium text-[13px]">Trend</p>
                        <p className={`my-2 flex items-center gap-x-1 text-[13px] text-${data.trend === 'upwards' ? '[#72f238]' : data.trend === 'downwards' ? '[#ff4949]' : 'yellow'}`} style={{
                            color: data.trend === 'upwards' ? colors?.profitColor : data.trend === 'downwards' ? colors?.lossColor : 'yellow'
                        }}>
                            {data.trend === 'upwards' ? <FaAngleDoubleUp /> : data.trend === 'downwards' ? <FaAngleDoubleDown /> : '—'} {data.percentageChange.toFixed(2)}%
                        </p>
                        <Sparklines data={data.prices} height={sparklineDimensions.height} limit={data.prices.length > 1000 ? 1000 : data.prices.length}>
                            <SparklinesCurve style={{ fill: "none" }} color={data.trend === 'upwards' ? colors?.profitColor : data.trend === 'downwards' ? colors?.lossColor : 'yellow'} />
                        </Sparklines>
                    </div>
                    <div className='absolute flex items-center text-gray-300 top-4 md:top-6 right-4 md:right-6 gap-x-1'>
                        <div className="flex items-center text-gray-300 gap-x-1 text-[13px]">
                            <FaClock className='w-[11px]' />
                            <p>{options.find(option => option.value === timerangepick)?.label}</p>
                        </div>
                    </div>
                </div>
                <div className="min-w-[180px] md:min-w-min p-4 md:p-6 text-left text-sm relative flex flex-col justify-between min-h-[115px] sm:min-h-[138px] md:min-h-[134px] md:max-h-[134px]" style={{ backgroundColor: colors?.secondaryColorBG }}>
                    <div>
                        <p className="mb-[15px] font-medium text-[13px]">Highest Price</p>
                        <p className="my-2 flex items-center text-[13px]" style={{ color: colors?.profitColor }}><span className='mr-3 text-gray-400'>H:</span> <FaAngleDoubleUp className='mr-1' />{getFormattedValue(data.highestPrice)}</p>
                        <div className="relative">
                            <Sparklines data={data.prices} height={sparklineDimensions.height} limit={data.prices.length > 1000 ? 1000 : data.prices.length}>
                                <SparklinesCurve style={{ fill: "none" }} color="lightgray" />
                            </Sparklines>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: `${getYCoordinate(data.highestPrice, 3)}px`,
                                    left: `${(data.prices.indexOf(data.highestPrice) / (data.prices.length - 1)) * 100}%`,
                                    transform: 'translate(-50%, -50%)'
                                }}
                            >
                                <svg height="7" width="7">
                                    <circle cx="3" cy="3" r="3.5" fill={colors?.profitColor} />
                                    <circle cx="3" cy="3" r="1.2" fill="white" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='absolute flex items-center text-gray-300 top-4 md:top-6 right-4 md:right-6 gap-x-1'>
                        <div className="flex items-center text-gray-300 gap-x-1 text-[13px]">
                            <FaClock className='w-[11px]' />
                            <p>{options.find(option => option.value === timerangepick)?.label}</p>
                        </div>
                    </div>
                </div>
                <div className="min-w-[180px] md:min-w-min p-4 md:p-6 text-left text-sm relative flex flex-col justify-between min-h-[115px] sm:min-h-[138px] md:min-h-[134px] md:max-h-[134px]" style={{ backgroundColor: colors?.secondaryColorBG }}>
                    <div>
                        <p className="mb-[15px] font-medium text-[13px]">Lowest Price</p>
                        <p className="my-2 flex items-center text-[13px]" style={{ color: colors?.lossColor }}><span className='mr-3 text-gray-400'>L:</span> <FaAngleDoubleDown className='mr-1' />{getFormattedValue(data.lowestPrice)}</p>
                        <div className="relative">
                            <Sparklines data={data.prices} height={sparklineDimensions.height} limit={data.prices.length > 1000 ? 1000 : data.prices.length}>
                                <SparklinesCurve style={{ fill: "none" }} color="lightgray" />
                            </Sparklines>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: `-5px`,
                                    left: `${(data.prices.indexOf(data.lowestPrice) / (data.prices.length - 1)) * 100}%`,
                                    transform: 'translate(-50%, -50%)'
                                }}
                            >
                                <svg height="7" width="7">
                                    <circle cx="3" cy="3" r="3.5" fill={colors?.lossColor} />
                                    <circle cx="3" cy="3" r="1.2" fill="white" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='absolute flex items-center text-gray-300 top-4 md:top-6 right-4 md:right-6 gap-x-1'>
                        <div className="flex items-center text-gray-300 gap-x-1 text-[13px]">
                            <FaClock className='w-[11px]' />
                            <p>{options.find(option => option.value === timerangepick)?.label}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;
