import "./Navbar.css";
import { Link } from "react-router-dom";
import { useContext, useState, useRef, useEffect, useMemo } from "react";
import { AuthContext } from "../../context/auth.context";
import ModalLoginReg from "../ModalLoginReg/ModalLoginReg";
import { FaRegBell, FaSignOutAlt, FaMoneyBill, FaBars } from "react-icons/fa";
import { useBalanceUser } from "../../context/balanceuser";
import { useWebSocket } from '../../context/WebSocketProvider';
import { AiOutlineDollar } from "react-icons/ai";
import { useNotificationsUser } from "../../context/notificationsuser.context";
import { IoMdClose } from "react-icons/io";
import { motion, AnimatePresence } from 'framer-motion';
import { BiSolidUpArrow } from "react-icons/bi";
import axios from "axios";

function Navbar() {
  const { isLoggedIn, user, logOutUser } = useContext(AuthContext);
  const { BalanceUser } = useBalanceUser();
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState()
  const { colors } = useWebSocket();
  const [displayBalance, setDisplayBalance] = useState(BalanceUser);
  const prevBalanceRef = useRef(BalanceUser);
  const animationRef = useRef();
  const { NotificationsUser, setNotificationsUser } = useNotificationsUser()
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleOpen = (mode) => {
    setMode(mode)
    setOpen(!open)
  }
  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2], 'F', 'F']; // For short hex, default alpha to 'FF' (1.0)
      } else if (c.length === 6) {
        c.push('F', 'F'); // If no alpha, default to 1.0
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 24) & 255, (c >> 16) & 255, (c >> 8) & 255, (c & 255) / 255].join(',') + ')';
    }
  }

  const newShade = (hexColor, magnitude) => {
    if (!hexColor) return;
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  const getFormattedValue = (value) => {
    return Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  };

  const animateBalance = (from, to, duration) => {
    const startTime = performance.now();

    const step = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1); // Ensure progress does not exceed 1
      const newBalance = from + (to - from) * progress;
      setDisplayBalance(getFormattedValue(newBalance));

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(step);
      }
    };

    animationRef.current = requestAnimationFrame(step);
  };

  // Effect to animate balance when BalanceUser changes
  useEffect(() => {
    const prevBalance = prevBalanceRef.current;
    const newBalance = parseFloat(BalanceUser);

    // Avoid animating if balance hasn't changed
    if (prevBalance !== newBalance) {
      animateBalance(prevBalance, newBalance, 450);
      prevBalanceRef.current = newBalance;
    }

    // Cleanup function to cancel animation if component unmounts or BalanceUser changes
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [BalanceUser]);

  const handleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  }

  const handleClearNotifications = () => {
    if (NotificationsUser.length === 0) return;
    axios.patch(process.env.REACT_APP_SERVER_URL + `/user/notifications/clear`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then(() => {
        setNotificationsUser([]);
      })
      .catch((error) => {
        console.error('Error clearing notifications:', error);
      });
  }

  // Initialize displayBalance on mount
  useEffect(() => {
    setDisplayBalance(getFormattedValue(BalanceUser));
    prevBalanceRef.current = parseFloat(BalanceUser);
  }, []);

  return (
    <nav className="flex h-[60px] text-white border-b border-[#00000059]  justify-between"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}
    >
      <div className="flex items-center pl-4 md:pl-6">
        <Link to="/">
          <img src="/logoNavbar.png" className="object-cover w-[100px] sm:w-32" />
        </Link>
      </div>
      <div className="flex items-center">
        {open && <ModalLoginReg open={open} setOpen={setOpen} handleOpen={handleOpen} mode={mode} />}

        {isLoggedIn && (
          <div className="flex items-center md:pr-6">
            <div className="flex items-center">
              <span className="balance px-3 sm:px-[18px] flex flex-row items-center gap-x-1" style={{
                backgroundColor: newShade(colors?.mainColorBG, -10),
              }}>
                $ {displayBalance}
              </span>
              <button className="font-bold deposit px-4 sm:px-[18px] md:mr-[136px] hover:brightness-110" style={{
                color: colors?.mainButton?.textColor,
                backgroundColor: colors?.mainButton?.mainColor,
                boxShadow: `0px 0px 10px ${hexToRgbA(colors?.mainButton?.shadowColor + "66")}, 0px 1px 0px ${hexToRgbA(newShade(colors?.mainButton?.mainColor, 40))} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(colors?.mainButton?.shadowColor + "66")} inset`,
              }}><span className="hidden sm:flex">CASHIER</span> <span className="flex sm:hidden"><FaMoneyBill className="w-4 h-4" /></span></button>
            </div>
            <button className="ml-5 mr-4 md:hidden">
              <FaBars className="w-6 h-6" />
            </button>
            <div className="items-center hidden gap-4 md:flex ">
              <img src={`images/avatar/${user?.avatar}`} alt="avatar" className="rounded-full w-[30px]" />
              <div className="flex flex-col items-start mr-6">
                <span>{user && user.username}</span>
                <span className="jaugeuser" style={{
                  background: `linear-gradient(90deg, ${colors?.firstColor} 0%, ${colors?.secondColor} 33%, ${colors?.thirdColor}  66%, ${colors?.fourthColor} 100%)`
                }}>
                  <span className="jaugeempty"></span>
                </span>
              </div>
              {/*               <div>
                <button className="text-xs text-[#B1B6C6]">
                  US
                </button>
              </div> */}
              <div className="relative flex items-center">
                <button className="relative text-[#B1B6C6]" /* onClick={() => handleNotifications()} */>
                  <FaRegBell />
                  {NotificationsUser.length !== 0 ? <span className="block float-right mt-[-20px] mr-[-2px] rounded-full h-1 w-1 bg-red-500"></span> : null}
                </button>
                <AnimatePresence>
                  {notificationsOpen && <div
                    className="absolute right-[1px] flex justify-end w-80 z-10 top-7 scroll">
                    <div className="px-3 py-2 text-xs rounded rounded-tr-none shadow-lg" style={{
                      backgroundColor: colors?.secondaryColorBG,
                    }}>
                      <BiSolidUpArrow className="absolute -top-3 -right-[1px] h-4 w-4" style={{
                        color: colors?.secondaryColorBG,
                      }} />
                      <div className="flex items-center justify-between">
                        <p className="uppercase text-[#B1B6C6]">Notifications</p>
                        <button className="px-2 py-1 text-black text-xs uppercase font-medium transition-all duration-200 hover:brightness-75 rounded-[5px] disabled:opacity-50 disabled:cursor-not-allowed" style={{
                          backgroundColor: colors?.secondaryButton?.mainColor,
                        }} onClick={() => handleClearNotifications()}>
                          <span>Clear all</span>
                        </button>
                      </div>
                      <motion.div
                        layout // Enable layout animations
                        initial={{ opacity: 1, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 1, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className="overflow-y-auto overflow-x-hidden max-h-80 min-w-[252px] mt-3">
                        {NotificationsUser.length !== 0 ? NotificationsUser.map((notification, index) => (
                          <div key={index} className="flex items-center w-full p-2 mb-2 rounded" style={{
                            backgroundColor: colors?.mainColorBG,
                          }}>
                            <div className="flex flex-col items-start">
                              <div className="pb-2">
                                <span>{notification.type}</span>
                              </div>
                              <div className="flex flex-row gap-x-2">
                                Coin: <img
                                  className="w-4 h-4 rounded-full"
                                  src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${notification.cryptoId}.png`}
                                  alt={`${notification.coin}`}
                                />
                                Wager: <span className="text-[#B1B6C6] flex flex-row items-center gap-x-0.5"><AiOutlineDollar color='#FFD700' />{notification.amount}</span>
                                Multiplier: <span className="text-[#B1B6C6] flex flex-row items-center">{notification.multiplier}<IoMdClose /></span>
                              </div>
                            </div>
                          </div>
                        )) : <p>No notifications!</p>}
                      </motion.div>
                    </div>
                  </div>
                  }
                </AnimatePresence>
              </div>
              <button onClick={logOutUser} className=" text-[#B1B6C6]"><FaSignOutAlt /></button>
            </div>
          </div>
        )}

        {!isLoggedIn && (
          <div className="flex items-center gap-4 pr-6">
            <div>
              <button onClick={() => handleOpen("Login")} className="text-sm font-bold uppercase">Login</button>
            </div>
            <div>
              <button onClick={() => handleOpen("Register")} className={`w-20 sm:w-28 py-2 sm:py-3 text-sm uppercase font-extrabold transition-all duration-200 hover:brightness-110 rounded-lg`}
                style={{
                  color: colors?.mainButton?.textColor,
                  backgroundColor: colors?.mainButton?.mainColor,
                  boxShadow: `0px 0px 10px ${hexToRgbA(colors?.mainButton?.shadowColor + "66")}, 0px 1px 0px ${hexToRgbA(newShade(colors?.mainButton?.mainColor, 40))} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(colors?.mainButton?.shadowColor + "66")} inset`,
                }}>Sign Up</button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;