import React, { useEffect, useState } from "react";
import axios from "axios";
import { notify } from "../Toast/Toast";
import { useWebSocket } from "../../context/WebSocketProvider";
import DashboardCasino from "./DashoardMenu";

const CasinoCryptocoins = () => {
  const [coins, setCoins] = useState([]);
  const { sendMessage, colors } = useWebSocket();

  useEffect(() => {
    const getCoins = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/crypto/cryptocoins`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setCoins(response.data);
    };
    getCoins();
  }, []);

  const handleActiveChange = (coin) => {
    axios
      .patch(
        process.env.REACT_APP_SERVER_URL + `/crypto/update/${coin._id}`,
        {
          active: !coin.active,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setCoins(coins.map((b) => (b._id === coin._id ? response.data : b)));
        const wsMessage = {
          type: "ActiveCoins",
        };
        sendMessage(wsMessage);
      });
  };
  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };
  return (
    <div className="flex flex-row"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col p-4">
          <div
            className="p-4 rounded-lg shadow-md text-center gap-x-2 items-center"
            style={{
              backgroundColor: newShade(colors.secondaryColorBG, 20),
            }}
          >
            <h1 className="mb-2 text-4xl font-bold text-white">Coin list</h1>
            <div className="flex flex-col pt-2 gap-y-2"></div>
            <div className="grid grid-cols-4 gap-y-1 overflow-y-auto max-h-[70vh]">
              {coins.map((coin) => (
                <div
                  key={coin._id}
                  className="rounded-lg shadow-md p-4 m-2 text-center justify-between text-white"
                  style={{
                    backgroundColor: newShade(colors.secondaryColorBG, 30),
                  }}
                >
                  <p className="font-semibold">{coin.name}</p>
                  <p className="flex justify-between text-sm">
                    <span>Position:</span>
                    {coin.pos}
                  </p>
                  <p className="flex justify-between text-sm">
                    <span>Sym:</span>
                    {coin.sym}
                  </p>
                  <p className="flex justify-between text-sm">
                    <span>Sym B:</span>
                    {coin.symb}
                  </p>
                  <p className="flex justify-between text-sm">
                    <span>Symb CB:</span>
                    {coin.symbCoinbase}
                  </p>
                  <p className="flex justify-between text-sm">
                    <span>ID:</span>
                    {coin.id}
                  </p>
                  <label className="flex items-center justify-center cursor-pointer select-none">
                    <div className="relative">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={coin.active}
                        onChange={() => handleActiveChange(coin)}
                      />
                      <div
                        className={`block ${coin.active ? "bg-green-500" : "bg-[#222636]"
                          } w-14 h-8 rounded-full`}
                        style={{
                          backgroundColor: coin.active
                            ? colors?.primaryColor
                            : colors?.mainColorBG,
                        }}
                      ></div>
                      <div
                        className={`absolute left-1 top-1 w-6 h-6 rounded-full bg-white transition-transform ${coin.active
                          ? "transform translate-x-full"
                          : "transform translate-x-0"
                          }`}
                      ></div>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoCryptocoins;
