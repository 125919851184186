import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './auth.context';
import { useWebSocket } from './WebSocketProvider'; // Import the WebSocket context

const NotificationsUserContext = createContext(null);

export const NotificationsUserProvider = ({ children }) => {
    const [NotificationsUser, setNotificationsUser] = useState([]);
    const { isLoggedIn, user } = useContext(AuthContext);  // Access authentication context

    // Function to fetch Notifications from the server
    const getNotifications = async () => {
        if (!isLoggedIn || !user) return;

        await axios
            .get(`${process.env.REACT_APP_SERVER_URL}/user/notifications`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            })
            .then((res) => {
                const unreadNotifications = res.data.notifications.filter(notification => notification.unread);
                const sortedNotifications = unreadNotifications.sort((a, b) => b.timestamp - a.timestamp);
                setNotificationsUser(sortedNotifications);
            })
            .catch((err) => {
                console.error('Error fetching Notifications:', err);
            });
    };

    // Update Notifications based on WebSocket messages
    useEffect(() => {
        if (isLoggedIn && user) {
            // Call getNotifications when the component mounts or user logs in
            getNotifications();
        }
    }, [user]);  // Rerun when bets or user status change

    return (
        <NotificationsUserContext.Provider value={{ NotificationsUser, setNotificationsUser, getNotifications }}>
            {children}
        </NotificationsUserContext.Provider>
    );
};

export const useNotificationsUser = () => useContext(NotificationsUserContext);
