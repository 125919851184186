import React from "react";
import { useWebSocket } from "../../context/WebSocketProvider";
import DashboardCasino from "./DashoardMenu";
import { useNavigate } from "react-router-dom";

const CasinoColors = () => {
  const { colors } = useWebSocket();
  const navigate = useNavigate();
  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2], "F", "F"]; // For short hex, default alpha to 'FF' (1.0)
      } else if (c.length === 6) {
        c.push("F", "F"); // If no alpha, default to 1.0
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [
          (c >> 24) & 255,
          (c >> 16) & 255,
          (c >> 8) & 255,
          (c & 255) / 255,
        ].join(",") +
        ")"
      );
    }
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <div className="flex flex-row"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full p-8">
        <div>
          <button
            className={`p-2 rounded-lg mr-2 hover:brightness-110 transition-all duration-200 ease-in-out`}
            onClick={() => navigate("/casino-dashboard/colors")}
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
            }}
          >
            <span
              className={`font-semibold transition-all duration-200`}
              style={{
                color:
                  window.location.pathname === "/casino-dashboard/colors" &&
                  colors?.profitColor,
                textShadow:
                  window.location.pathname === "/casino-dashboard/colors" &&
                  `0px 0px 8px ${colors?.profitColor}`,
              }}
            >
              Colors
            </span>
          </button>
          <button
            className={`p-2 rounded-lg mr-2 hover:brightness-110 transition-all duration-200 ease-in-out text-[#b1b6c6]`}
            onClick={() => navigate("/casino-dashboard/colors/edit")}
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
            }}
          >
            <span
              className={`font-semibold transition-all duration-200
              `}
              style={{
                color:
                  window.location.pathname ===
                  "/casino-dashboard/colors/edit" && colors?.profitColor,
                textShadow:
                  window.location.pathname ===
                  "/casino-dashboard/colors/edit" &&
                  `0px 0px 8px ${colors?.profitColor}`,
              }}
            >
              Edit Colors
            </span>
          </button>
        </div>
        <div className="p-4 mt-2 rounded-md">
          <h1 className="mb-5 text-4xl font-bold text-white">Colors</h1>
          <div>
            {colors && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-h-[75vh] w-full">
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Main Button
                  </p>
                  <button
                    className={`w-full py-3 text-sm uppercase font-extrabold transition-all duration-200 hover:brightness-110 rounded-lg`}
                    style={{
                      color: colors?.mainButton?.textColor,
                      backgroundColor: colors?.mainButton?.mainColor,
                      boxShadow: `0px 0px 10px ${hexToRgbA(
                        colors?.mainButton?.shadowColor + "66"
                      )}, 0px 1px 0px ${hexToRgbA(
                        newShade(colors?.mainButton?.mainColor, 40)
                      )} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(
                        colors?.mainButton?.shadowColor + "66"
                      )} inset`,
                    }}
                  >
                    Main Button
                  </button>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Secondary Button
                  </p>
                  <button
                    className={`w-full py-3 text-sm uppercase font-extrabold transition-all duration-200 hover:brightness-110 rounded-lg`}
                    style={{
                      color: colors?.secondaryButton?.textColor,
                      backgroundColor: colors?.secondaryButton?.mainColor,
                      boxShadow: `0px 0px 10px ${hexToRgbA(
                        colors?.secondaryButton?.shadowColor + "66"
                      )}, 0px 1px 0px ${hexToRgbA(
                        newShade(colors?.secondaryButton?.shadowColor, 40)
                      )} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(
                        colors?.secondaryButton?.shadowColor + "66"
                      )} inset`,
                    }}
                  >
                    Sec. Button
                  </button>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Gradient Colors
                  </p>
                  <div
                    className="w-full mt-2 rounded h-9"
                    style={{
                      background: `linear-gradient(to right, ${colors?.firstColor}, ${colors?.secondColor}, ${colors?.thirdColor}, ${colors?.fourthColor})`,
                    }}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Main Background Color
                  </p>
                  <div
                    className="w-full mt-2 rounded h-9"
                    style={{
                      background: colors?.mainColorBG,
                    }}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Sec. Background Color
                  </p>
                  <div
                    className="w-full mt-2 rounded h-9"
                    style={{
                      background: colors?.secondaryColorBG,
                    }}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Profit Color
                  </p>
                  <div
                    className="w-full mt-2 rounded h-9"
                    style={{
                      background: colors?.profitColor,
                    }}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">Loss Color</p>
                  <div
                    className="w-full mt-2 rounded h-9"
                    style={{
                      background: colors?.lossColor,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoColors;
