import React, { useState, useContext, useEffect, useRef } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { AuthContext } from "../../../context/auth.context";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useWebSocket } from "../../../context/WebSocketProvider";
import axios from 'axios';
import cryptoConfig from '../../../cryptoConfig.js';
import { useBalanceUser } from '../../../context/balanceuser.jsx';
import { useBetContext } from "../../../context/bet.context.jsx";
import { MdOutlineAttachMoney } from "react-icons/md";

export default function Modalbetmobile({ open, setOpen, coin }) {
    const { isLoggedIn, user } = useContext(AuthContext);
    const { publicBets, sendMessage, closedBets, activeBets, cryptoData, colors } = useWebSocket();
    const [bet, setBet] = useState('up');
    const [amount, setAmount] = useState(10.00);
    const [result, setResult] = useState(null);
    const [betLocked, setBetLocked] = useState(false);
    const [multiplier, setMultiplier] = useState(10);
    const [activeTab, setActiveTab] = useState('PUBLIC');
    const [openTooltip, setOpenTooltip] = useState(null);
    const tooltipRef = useRef(null);
    const cryptoId = cryptoConfig[coin].id;
    const steps = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000];
    const currentPrice = cryptoData.data[coin] ? cryptoData.data[coin] : null;
    const { BalanceUser } = useBalanceUser();
    const { selectedBet, setSelectedBet } = useBetContext();

    const handleSelectBet = (bet) => {
        setSelectedBet(bet);
    };

    const [isClosing, setIsClosing] = useState(false); // State to manage closing animation

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setOpenTooltip(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [tooltipRef]);

    const calculateTriggerPrice = (entryPrice, multiplier, bet) => {
        const MIN_CHANGE = 0.00040; // Minimum change to prevent bust price being zero

        // Ensure the multiplier is greater than 1
        if (multiplier <= 1) {
            multiplier = 1 + MIN_CHANGE;
        }

        // Determine the price change based on the bet direction
        const P_close = bet === 'up' ? entryPrice * (1 - 1 / multiplier) : entryPrice * (1 + 1 / multiplier);

        // Return the calculated trigger price with the appropriate precision
        return P_close.toFixed(cryptoConfig[coin]?.precision);
    };

    const handleOpenWithAnimation = () => {
        if (open) {
            // If modal is open, initiate close animation
            setIsClosing(true);
        } else {
            setOpen(true); // Directly open the modal
        }
    };

    const handleAnimationEnd = () => {
        if (isClosing) {
            setIsClosing(false); // Reset closing state
            setOpen(false); // Actually close the modal after animation
        }
    };

    const handleBet = async () => {
        if (!isLoggedIn) {
            alert("You must be logged in to place a bet.");
            return;
        }

        const currentPriceBet = currentPrice;

        setResult(null);
        setBetLocked(true);

        // Calculate bust price
        const bustPriceBet = calculateTriggerPrice(currentPriceBet, multiplier, bet);

        // Construct the bet data
        const betData = {
            userId: user?._id,
            username: user?.username,
            direction: bet,
            amount: parseFloat(amount),
            multiplier: parseFloat(multiplier),
            entryPrice: currentPriceBet,
            bustPrice: bustPriceBet,
            avatar: user?.avatar,
            cryptoId: cryptoId,
            coin: coin,
            balance: BalanceUser
        };

        try {
            const response = await axios.post(process.env.REACT_APP_SERVER_URL + `/bet/addBet`, { betData }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            if (response.status !== 201) {
                throw new Error('Failed to save bet');
            }

            const savedBet = response.data;
            setBetLocked(false);
            setActiveTab('ACTIVE');
            handleSelectBet(savedBet);

            // Send the bet data to the WebSocket server
            const wsMessage = {
                type: 'newBet',
                ...betData,
                _id: savedBet._id,
                result: savedBet.result,
                createdAt: savedBet.createdAt
            };
            sendMessage(wsMessage);
            handleOpenWithAnimation();
        } catch (error) {
            console.error('Error saving bet:', error);
            alert('There was an error placing your bet. Please try again.');
            setBetLocked(false);
        }
    };

    const handleMultiplierChange = (e) => {
        const value = parseInt(e.target.value);
        setMultiplier(Math.min(1000, Math.max(1, value)));
    };

    const handleSliderChange = (e) => {
        const index = parseInt(e.target.value);
        setMultiplier(steps[index]);
    };

    const getSliderValue = () => {
        return steps.indexOf(multiplier);
    };

    const isValidMultiplier = multiplier >= 1 && multiplier <= 1000;

    return (
        <Dialog as="div" open={open} className="relative z-[150] lg:hidden" onClose={() => handleOpenWithAnimation()}>
            <DialogBackdrop className={`fixed inset-0 transition-opacity ${isClosing ? "betbackdropclose" : "betbackdropopen"}`} style={{
                backgroundColor: colors?.mainColorBG + "90",
            }} />
            <div
                className={`fixed inset-0 z-[150] w-screen flex justify-center ${isClosing ? 'betmobileclose' : 'betmobileopen'}`}
                onAnimationEnd={handleAnimationEnd}
            >
                <div className="absolute bottom-0 h-[55vh] min-w-full sm:min-w-0 text-center sm:items-center sm:p-0 sm:max-w-lg sm:w-full">
                    <DialogPanel className="relative h-full overflow-hidden text-left shadow-xl rounded-tr-3xl rounded-tl-3xl sm:my-8 sm:w-full"
                        style={{
                            backgroundColor: colors?.secondaryColorBG,
                        }}>
                        <div className="h-full px-[32px] pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="text-[#b1b6c6] justify-end">
                                <div className='flex items-center justify-between'>
                                    <p className='text-lg font-bold text-left text-white'>Place Bet</p>
                                    <button className='text-xl' onClick={() => handleOpenWithAnimation()}><IoMdClose /></button>
                                </div>
                                <div className='pt-7'>
                                    <div className='flex flex-row justify-between gap-x-3'>
                                        <div className='w-3/5'>
                                            <h2 className="mb-2 text-[13px] text-left">Price up or down?</h2>
                                            <div className={`flex flex-row mb-6 w-full p-0.5 rounded-md ${betLocked ? 'pointer-events-none opacity-50' : ''}`}
                                                style={{
                                                    backgroundColor: colors?.mainColorBG,
                                                }}>
                                                <button
                                                    onClick={() => setBet('up')}
                                                    className={` flex flex-row justify-center items-center gap-x-1 p-2 rounded text-[13px] transition-all duration-200 w-full
                ${betLocked ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    style={{
                                                        backgroundColor: bet === 'up' && colors?.profitColor + '21',
                                                        color: bet === 'up' ? colors?.profitColor : '#b1b6c6',
                                                    }}
                                                >
                                                    <FaAngleDoubleUp />
                                                    Up
                                                </button>
                                                <button
                                                    onClick={() => setBet('down')}
                                                    className={`flex flex-row justify-center items-center gap-x-1 p-2 rounded text-[13px] transition-all duration-200 w-full
                ${betLocked ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    style={{
                                                        backgroundColor: bet === 'down' && colors?.lossColor + '21',
                                                        color: bet === 'down' ? colors?.lossColor : '#b1b6c6',
                                                    }}
                                                >
                                                    <FaAngleDoubleDown />
                                                    Down
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`w-2/5 mb-6 text-left ${betLocked ? 'pointer-events-none opacity-50' : ''}`}>
                                            <label className="flex mb-2 text-[13px]">Bet Amount</label>
                                            <div className="relative text-[13px]">
                                                <input
                                                    type="number"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    className="w-full p-2.5 text-white rounded focus:outline-none pl-8"
                                                    disabled={betLocked}
                                                    style={{
                                                        backgroundColor: colors?.mainColorBG,
                                                    }}
                                                />
                                                <span
                                                    onClick={() => setAmount((prev) => (parseFloat(prev) / 2).toFixed(2))}
                                                    className="absolute text-white transform -translate-y-1/2 left-4 top-[48%]"
                                                >
                                                    <MdOutlineAttachMoney />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`w-full text-left ${betLocked ? 'pointer-events-none opacity-50' : ''}`}>
                                        <label className="flex mb-1 text-[13px]">Payout Multiplier</label>
                                        <div className='relative flex flex-row gap-x-3'>
                                            <input
                                                type="number"
                                                min="1"
                                                max="1000"
                                                value={multiplier}
                                                onChange={handleMultiplierChange}
                                                onBlur={handleMultiplierChange}
                                                className="w-3/5 p-2 text-white rounded focus:outline-none pl-8 text-[13px] font-light"
                                                disabled={betLocked}
                                                style={{
                                                    backgroundColor: colors?.mainColorBG,
                                                }}
                                            />
                                            <span className='absolute text-sm font-semibold text-white transform -translate-y-1/2 left-3 top-1/2'><IoClose /></span>
                                            <div className="flex flex-col justify-center w-2/5 pl-4 text-[11px]">
                                                <span className="">Bust Price:</span>
                                                <span className="text-white">{calculateTriggerPrice(currentPrice, multiplier, bet)}</span>
                                            </div>
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max={steps.length - 1}
                                            value={getSliderValue()}
                                            onChange={handleSliderChange}
                                            className="w-full mt-6 custom-slider"
                                            style={{
                                                background: `linear-gradient(90deg, ${colors?.firstColor} 0%, ${colors?.secondColor} 33%, ${colors?.thirdColor}  66%, ${colors?.fourthColor} 100%)`
                                            }}
                                            disabled={betLocked}
                                        />
                                        {!isValidMultiplier && (
                                            <p className="mt-2 text-sm text-[#FF4949]">Multiplier must be between 1 and 1000</p>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between mb-6 text-[11px]">
                                        <span className="text-white">x1 <span style={{ color: colors?.profitColor }}>Safe</span></span>
                                        <span style={{ color: colors?.lossColor }}>Wild <span className='text-white'>x1000</span></span>
                                    </div>
                                    <button
                                        onClick={handleBet}
                                        className={`w-full py-3 text-black text-sm uppercase font-medium transition-all duration-200 
                                            ${bet === 'up' ? ' hover:brightness-75' : bet === 'down' ? 'hover:brightness-75' : 'bg-gray-700'}
                                            rounded-[5px] disabled:opacity-50 disabled:cursor-not-allowed`}
                                        disabled={!amount || !bet || !isValidMultiplier || betLocked}
                                        style={{
                                            backgroundColor: bet === 'up' ? colors?.profitColor : colors?.lossColor,
                                        }}>
                                        Place Bet
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
