import React, { useState } from "react";
import { useWebSocket } from "../../context/WebSocketProvider";
import DashboardCasino from "./DashoardMenu";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import axios from "axios";

const CasinoColorsEdit = () => {
  const { colors, sendMessage } = useWebSocket();
  const navigate = useNavigate();
  const [openPicker, setOpenPicker] = useState(null);

  const [showColors, setShowColors] = useState({
    mainButton: {
      mainColor: colors?.mainButton?.mainColor,
      shadowColor: colors?.mainButton?.shadowColor,
      textColor: colors?.mainButton?.textColor,
    },
    secondaryButton: {
      mainColor: colors?.secondaryButton?.mainColor,
      shadowColor: colors?.secondaryButton?.shadowColor,
      textColor: colors?.secondaryButton?.textColor,
    },
    firstColor: colors?.firstColor,
    secondColor: colors?.secondColor,
    thirdColor: colors?.thirdColor,
    fourthColor: colors?.fourthColor,
    mainColorBG: colors?.mainColorBG,
    secondaryColorBG: colors?.secondaryColorBG,
    profitColor: colors?.profitColor,
    lossColor: colors?.lossColor,
  });

  const toggleColorPicker = (pickerName) => {
    setOpenPicker((prevPicker) =>
      prevPicker === pickerName ? null : pickerName
    );
  };

  const handleChange = (color, section, property = null) => {
    setShowColors((prevColors) => {
      // Check if a property is provided (for nested properties)
      if (property) {
        return {
          ...prevColors,
          [section]: {
            ...prevColors[section],
            [property]: color.hex,
          },
        };
      } else {
        // Directly update the color if there is no nested property
        return {
          ...prevColors,
          [section]: color.hex,
        };
      }
    });
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2], "F", "F"]; // For short hex, default alpha to 'FF' (1.0)
      } else if (c.length === 6) {
        c.push("F", "F"); // If no alpha, default to 1.0
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [
          (c >> 24) & 255,
          (c >> 16) & 255,
          (c >> 8) & 255,
          (c & 255) / 255,
        ].join(",") +
        ")"
      );
    }
  };

  const ColorPicker = ({ label, color = "#ffffff", onChange, pickerName }) => {
    return (
      <div className="relative flex flex-col w-full mt-2">
        <p className="text-sm text-white">{label}</p>
        <button
          className="w-full rounded-lg h-9"
          style={{ backgroundColor: color }}
          onClick={() => toggleColorPicker(pickerName)}
        />
        {openPicker === pickerName && (
          <SketchPicker
            className="absolute z-50 top-14"
            color={color}
            onChange={onChange}
          />
        )}
      </div>
    )
  }


  const handleSubmit = () => {
    axios
      .put(
        process.env.REACT_APP_SERVER_URL + `/settings/colors`,
        {
          showColors,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const wsMessage = {
          type: "colorUpdate",
        };
        sendMessage(wsMessage)
      });
  };

  return (
    <div className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col items-center w-full p-8">
        <div>
          <button
            className={`p-2 rounded-lg mr-2 hover:brightness-110 transition-all duration-200 ease-in-out text-[#b1b6c6]`}
            onClick={() => navigate("/casino-dashboard/colors")}
            style={{
              backgroundColor: newShade(showColors.secondaryColorBG, 10),
            }}
          >
            <span
              className={`font-semibold transition-all duration-200`}
              style={{
                color:
                  window.location.pathname === "/casino-dashboard/colors" &&
                  showColors.profitColor,
                textShadow:
                  window.location.pathname === "/casino-dashboard/colors" &&
                  `0px 0px 8px ${showColors.profitColor}`,
              }}
            >
              Colors
            </span>
          </button>
          <button
            className={`p-2 rounded-lg mr-2 hover:brightness-110 transition-all duration-200 ease-in-out text-[#b1b6c6]`}
            onClick={() => navigate("/casino-dashboard/colors/edit")}
            style={{
              backgroundColor: newShade(showColors.secondaryColorBG, 10),
            }}
          >
            <span
              className={`font-semibold transition-all duration-200
              `}
              style={{
                color:
                  window.location.pathname ===
                  "/casino-dashboard/colors/edit" && showColors.profitColor,
                textShadow:
                  window.location.pathname ===
                  "/casino-dashboard/colors/edit" &&
                  `0px 0px 8px ${showColors.profitColor}`,
              }}
            >
              Edit Colors
            </span>
          </button>
        </div>
        <div className="p-4 mt-2 rounded-md">
          <h1 className="mb-5 text-4xl font-bold text-white">Colors Edit</h1>
          <div>
            {colors && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-h-[75vh] w-full">
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Main Button
                  </p>
                  <button
                    className={`w-full py-3 text-sm uppercase font-extrabold transition-all duration-200 hover:brightness-110 rounded-lg`}
                    style={{
                      color: showColors.mainButton?.textColor,
                      backgroundColor: showColors.mainButton?.mainColor,
                      boxShadow: `0px 0px 10px ${hexToRgbA(
                        showColors.mainButton?.shadowColor + "66"
                      )}, 0px 1px 0px ${hexToRgbA(
                        newShade(showColors.mainButton?.mainColor, 40)
                      )} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(
                        showColors.mainButton?.shadowColor + "66"
                      )} inset`,
                    }}
                  >
                    Main Button
                  </button>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Main Button Color"
                      color={showColors.mainButton?.mainColor}
                      onChange={(color) =>
                        handleChange(color, "mainButton", "mainColor")
                      }
                      pickerName="MainButtonColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Main Button Shadow Color"
                      color={showColors.mainButton?.shadowColor}
                      onChange={(color) =>
                        handleChange(color, "mainButton", "shadowColor")
                      }
                      pickerName="MainButtonShadowColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Main Button Text Color"
                      color={showColors.mainButton?.textColor}
                      onChange={(color) =>
                        handleChange(color, "mainButton", "textColor")
                      }
                      pickerName="MainButtonTextColor"
                    />
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Secondary Button
                  </p>
                  <button
                    className={`w-full py-3 text-sm uppercase font-extrabold transition-all duration-200 hover:brightness-110 rounded-lg`}
                    style={{
                      color: showColors.secondaryButton?.textColor,
                      backgroundColor: showColors.secondaryButton?.mainColor,
                      boxShadow: `0px 0px 10px ${hexToRgbA(
                        showColors.secondaryButton?.shadowColor + "66"
                      )}, 0px 1px 0px ${hexToRgbA(
                        newShade(showColors.secondaryButton?.mainColor, 40)
                      )} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(
                        showColors.secondaryButton?.shadowColor + "66"
                      )} inset`,
                    }}
                  >
                    Sec. Button
                  </button>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Secondary Button Color"
                      color={showColors.secondaryButton?.mainColor}
                      onChange={(color) =>
                        handleChange(color, "secondaryButton", "mainColor")
                      }
                      pickerName="SecondaryButtonColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Secondary Button Shadow Color"
                      color={showColors.secondaryButton?.shadowColor}
                      onChange={(color) =>
                        handleChange(color, "secondaryButton", "shadowColor")
                      }
                      pickerName="SecondaryButtonShadowColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Secondary Button Text Color"
                      color={showColors.secondaryButton?.textColor}
                      onChange={(color) =>
                        handleChange(color, "secondaryButton", "textColor")
                      }
                      pickerName="SecondaryButtonTextColor"
                    />
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Gradient Colors
                  </p>
                  <div
                    className="w-full mt-2 rounded h-9"
                    style={{
                      background: `linear-gradient(to right, ${showColors.firstColor}, ${showColors.secondColor}, ${showColors.thirdColor}, ${showColors.fourthColor})`,
                    }}
                  ></div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="First Gradient Color"
                      color={showColors.firstColor}
                      onChange={(color) => handleChange(color, "firstColor")}
                      pickerName="FirstColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Second Gradient Color"
                      color={showColors.secondColor}
                      onChange={(color) => handleChange(color, "secondColor")}
                      pickerName="SecondColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Third Gradient Color"
                      color={showColors.thirdColor}
                      onChange={(color) => handleChange(color, "thirdColor")}
                      pickerName="ThirdColor"
                    />
                  </div>
                  <div className="relative flex flex-col w-full mt-2">
                    <ColorPicker
                      label="Fourth Gradient Color"
                      color={showColors.fourthColor}
                      onChange={(color) => handleChange(color, "fourthColor")}
                      pickerName="FourthColor"
                    />
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Main Background Color
                  </p>
                  <ColorPicker
                    color={showColors.mainColorBG}
                    onChange={(color) => handleChange(color, "mainColorBG")}
                    pickerName="MainColorBG"
                  />
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Sec. Background Color
                  </p>
                  <ColorPicker
                    color={showColors.secondaryColorBG}
                    onChange={(color) =>
                      handleChange(color, "secondaryColorBG")
                    }
                    pickerName="SecondaryColorBG"
                  />
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">
                    Profit Color
                  </p>
                  <ColorPicker
                    color={showColors.profitColor}
                    onChange={(color) => handleChange(color, "profitColor")}
                    pickerName="ProfitColor"
                  />
                </div>
                <div
                  className="flex flex-col items-center justify-center p-4 text-center rounded-lg shadow-md"
                  style={{
                    backgroundColor: newShade(showColors.secondaryColorBG, 20),
                  }}
                >
                  <p className="text-xl font-semibold text-white">Loss Color</p>
                  <ColorPicker
                    color={showColors.lossColor}
                    onChange={(color) => handleChange(color, "lossColor")}
                    pickerName="LossColor"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <button className="w-1/3 p-2 rounded text-black text-sm uppercase font-medium transition-all duration-200 bg-[#72F238] hover:brightness-110" style={{
          backgroundColor: showColors?.profitColor,
        }} onClick={() => handleSubmit()}>
          Submit Changes
        </button>
      </div>
    </div>
  );
};

export default CasinoColorsEdit;
