import "./App.css";
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Navbar from "./components/Navbar/Navbar";
import CasinoStatistics from "./components/CasinoDashboard/CasinoStatistics";

import IsPrivate from "./components/IsPrivate/IsPrivate";
/* import IsAnon from "./components/IsAnon/IsAnon";
import IsAdmin from "./components/IsAdmin/IsAdmin"; */
import IsCasino from "./components/IsCasino/IsCasino";
import CasinoCryptocoins from "./components/CasinoDashboard/CasinoCryptocoins";
import CasinoUsers from "./components/CasinoDashboard/CasinoUsers";
import CasinoBots from "./components/CasinoDashboard/CasinoBots";
import CasinoBotsCreate from "./components/CasinoDashboard/CasinoBotsCreate";
import CasinoBets from "./components/CasinoDashboard/CasinoBets";
import CasinoColors from "./components/CasinoDashboard/CasinoColors";
import CasinoSettings from "./components/CasinoDashboard/CasinoSettings";
import CasinoColorsEdit from "./components/CasinoDashboard/CasinoColorsEdit";

function App() {

/*   useEffect(() => {
    const parentOrigin = 'http://localhost:5000'; // Parent origin
    const iframeOrigin = window.location.origin; // Iframe's own origin

    const handleMessage = (event) => {
      const { origin, data } = event;

      if (origin === parentOrigin) {
        const { type, payload } = data;

        switch (type) {
          case 'USER_INFO':
            console.log('Received USER_INFO:', payload);

            // Respond back to the parent indicating receipt
            const response = {
              type: 'IFRAME_RESPONSE',
              payload: {
                status: 'received',
                message: 'User info received successfully.',
              },
            };
            window.parent.postMessage(response, parentOrigin);
            break;
          default:
            console.warn('Unknown message type:', type);
        }
      } else if (origin === iframeOrigin) {
        // Silently ignore messages from itself (e.g., webpackWarnings)
        return;
      } else {
        // Log warnings for unauthorized origins
        console.warn('Origin not allowed:', origin);
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []); */

  return (
    <div className="App scrollbar">
      <Navbar />

      <Routes>
        <Route path="/" element={<Navigate to="/BTC" />} />
        <Route path="/:coin" element={<HomePage />} />
        <Route
          path="/casino-dashboard/statistics"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoStatistics />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/crypto-coins"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoCryptocoins />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/users"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoUsers />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/bots"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBots />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/bots/create"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBotsCreate />
              </IsCasino>
            </IsPrivate>
          }
        />

        <Route
          path="/casino-dashboard/bets"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBets />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/colors"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoColors />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/colors/edit"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoColorsEdit />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino-dashboard/settings"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoSettings />
              </IsCasino>
            </IsPrivate>
          }
        />

        <Route
          path="/profile"
          element={
            <IsPrivate>
              <ProfilePage />
            </IsPrivate>
          }
        />

      </Routes>
      <ToastContainer className='mr-5' />
    </div >
  );
}

export default App;
